import ApostaDTO from 'dtos/ApostaDTO';
import JogoDTO from 'dtos/JogoDTO';
import RodadaDTO from 'dtos/RodadaDTO';
import { createContext } from 'react';
const BetsService = createContext({});

export type ContextDATA = {
    rodada?: RodadaDTO; 
    setRodada?: () => {};
    setIsLoading?:() => {}; 
    toast?: any;
    rodadas?: RodadaDTO[];
    setRodadas?:() => {};
    apostas?: ApostaDTO[] 
    setApostas?:() => {};
    saldo:number;
    jogos: JogoDTO[];
    setJogos: () => {};

}

export default BetsService;

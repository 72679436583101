import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns-tz';
import RodadaDTO from 'dtos/RodadaDTO';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import JogoDTO from 'dtos/JogoDTO';
import { formataMoeda, mensagemErro } from 'Utils';
import AcumuladoDTO from 'dtos/AcumuladoDTO';
import { useContext, useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import BetsService from 'contexts/BetsService';
import { apiToken } from 'api';
import './style.css';


type Props = {
    jogos?: JogoDTO[];
    palpites: number[];
    configura: any;
    valorPalpite: any;
    rodada: RodadaDTO;
    qtdApostas: number;
};

const Palpites = ({ jogos, palpites,configura,rodada,valorPalpite,qtdApostas }: Props) => {
  const [acumulado, setAcumulado] = useState<AcumuladoDTO>();
  const { setIsLoading, toast, user }: any = useContext(BetsService);

  

  useEffect(() => {
    const carregarDados = async () => {
      const config: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${user?.token}` },
      };

      if (user) {
        setIsLoading(true);
        apiToken
          .get(`users/${user?.id}/acumulado`, config)
          .then((a) => {
            setAcumulado(a.data);
            setIsLoading(false);
          })
          .catch((e) => {
            toast.error(mensagemErro(e));
            setIsLoading(false);
          });
      } 
    };
    carregarDados();
  }, []);


  return (
    <div className="big-container">
      <div className="container">
        <div className="container-item-nova-aposta-title">JOGOS DA RODADA</div>

        {jogos?.map((j, x) => (
          <div key={x} className="container-item-nova-aposta">
            <div className="container-item-nova-aposta-ordem">j{j.ordem}</div>
            <div
              className={
                palpites[j.ordem - 1] === 1 ? 'selecionado' : 'nao-selecionado'
              }
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <img
                alt=""
                src={j.timeCasa.logo}
                title={j.timeCasa.name}
                onClick={() => configura(x, 1)}
              />
              <div style={{ fontSize: '10px' }}>{j.timeCasa.sigla}</div>
            </div>

            <div
              className={
                palpites[j.ordem - 1] === 0
                  ? 'container-item-nova-aposta-empate selecionado'
                  : 'container-item-nova-aposta-empate nao-selecionado'
              }
              onClick={() => configura(x, 0)}
            >
              X
            </div>
            <div
              className={
                palpites[j.ordem - 1] == 2 ? 'selecionado' : 'nao-selecionado'
              }
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <img
                alt=""
                src={j.timeFora.logo}
                title={j.timeFora.name}
                onClick={() => configura(x, 2)}
              />
              <div style={{ fontSize: '10px' }}>{j.timeFora.sigla}</div>
            </div>

            <div className="container-item-nova-aposta-local">
              {format(
                rodada ? new Date(j.dataEvento) : new Date(),'dd/MM/yyyy HH:mm')}
              <br />
              <div style={{ fontWeight: 'normal', fontSize: '8px' }}>{j.campeonato.name}</div>
            </div>
            <div style={{ marginTop: -15 }}>
              <a href={j.link} target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faExternalLink}
                  className="icon-edit"
                  title="Visitar site do Jogo"
                />
              </a>
            </div>
          </div>
        ))}
      </div>
      {palpites &&
      <div className="container">
        <div className="container-item-nova-aposta-palpite-title">PALPITE { rodada.pagaAcumulado ? formataMoeda(acumulado && qtdApostas == 0 && acumulado.percPremio <100? acumulado.vlrCartela: rodada.valor):  formataMoeda(rodada.valor)}</div>
        {palpites?.map((j, x) => (
          <div key={x} className="container-item-nova-aposta-palpite">
            {valorPalpite(x, jogos)}
          </div>
        ))}
      </div>
        }
    </div>
  );
};

export default Palpites;

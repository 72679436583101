import React, { useState, useContext } from 'react';
import './style.css';
import { apiBasic } from 'api';
import ButtonIcon from 'components/ButtonIcon';
import BetsService from 'contexts/BetsService';

type FormCadastro = {
  nome: string;
  login: string;
  senha: string;
  senhaRepeticao: string;
  promotor: string;
  palpiteiro: string;
};

type Props = {
  setIsNew: any;
};

const CadUser = ({ setIsNew }: Props) => {
  const { setIsLoading, toast }: any = useContext(BetsService);

  const [formCadastro, setFormCadastro] = useState<FormCadastro>({
    login: '',
    nome: '',
    senha: '',
    senhaRepeticao: '',
    promotor: 'false',
    palpiteiro: 'false',
  });

  const handleChangeCad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const campo = event.target.name;
    const valor = event.target.value;
    setFormCadastro({ ...formCadastro, [campo]: valor });
  };

  const handleSubmitCadastro = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const userInput = {
      email: formCadastro.login,
      nome: formCadastro.nome,
      password: formCadastro.senha,
      password2: formCadastro.senhaRepeticao,
      tipo: formCadastro.palpiteiro ? 'APOSTADOR' : 'VENDEDOR',
    };
    setIsLoading(true);

    apiBasic
      .post('/users', userInput)
      .then(function (res) {
        setIsLoading(false);
        setIsNew(false);
        toast.success('Usuário cadastrado com sucesso !!!');
      })
      .catch((e) => {
        if (e.response.data.erros) {
          e.response.data.erros.map((a: any) => {
            toast.error(a.message);
          });
        } else {
          toast.error('Não foi possível realizar o cadastro no momento.');
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="home-right-container-top">CADASTRO DE USUÁRIOS</div>
      <div className="dados-acesso">
        <form onSubmit={handleSubmitCadastro} style={{ fontSize: 16 }}>
          <div style={{ fontSize: 14 }}>LOGIN (e-mail)</div>
          <input
            required
            style={{ width: '100%' }}
            name="login"
            value={formCadastro.login}
            onChange={handleChangeCad}
          />
          <div style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>
            NOME (Apelido)
          </div>
          <input
            required
            style={{ width: '100%' }}
            name="nome"
            maxLength={30}
            value={formCadastro.nome}
            onChange={handleChangeCad}
          />
          <div style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>
            SENHA
          </div>
          <input
            required
            style={{ width: '100%' }}
            name="senha"
            value={formCadastro.senha}
            type="password"
            onChange={handleChangeCad}
          />
          <div style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>
            REPITA SENHA
          </div>

          <input
            required
            style={{ width: '100%' }}
            name="senhaRepeticao"
            value={formCadastro.senhaRepeticao}
            type="password"
            onChange={handleChangeCad}
          />
          <div style={{ fontSize: 14, marginTop: 10 }}>TIPO</div>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 14, marginTop: 5, marginRight: 25 }}>
              <input
                required
                type="radio"
                name="palpiteiro"
                id="palpiteiro"
                value={formCadastro.palpiteiro}
                checked
                onChange={handleChangeCad}
                style={{ marginRight: 10 }}
              />
              PALPITEIRO
            </div>

            <div style={{ fontSize: 14, marginTop: 5, marginBottom: 5 }}>
              <input
                type="radio"
                name="promotor"
                id="promotor"
                value={formCadastro.promotor}
                style={{ marginRight: 10 }}
              />
              PROMOTOR
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '15px 0px',
            }}
          >
            <ButtonIcon submit={true} text="CADASTRAR" position={'right'} />
            <ButtonIcon
              text="CANCELAR"
              action={() => setIsNew(false)}
              position={'left'}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default CadUser;

import { useState, useContext, useEffect } from 'react';
import './style.css';
import { apiToken } from 'api';
import BetsService from 'contexts/BetsService';
import RodadaDTO from 'dtos/RodadaDTO';
import JogoDTO from 'dtos/JogoDTO';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { NumericFormat } from 'react-number-format';
import { Form, Row, Col, Input, Button, Select, DatePicker } from 'antd';
import JogoItem from 'components/JogoItem';
import ButtonIcon from 'components/ButtonIcon';
import moment from 'moment-timezone';
import { fmtDate, fmtValor, mensagemErro } from 'Utils';
import CadJogo from 'pages/CadJogo';
import { AxiosRequestConfig } from 'axios';
import CampeonatoDTO from 'dtos/CampeonatoDTO';
type Props = {
  setIsNew: any;
  rodada: RodadaDTO;
  setRodada: any;
  reload: any;
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const CadRodada = ({ setIsNew, rodada, reload }: Props) => {
  moment.tz.setDefault('UTC-3');

  const { user, setIsLoading, toast, setRodada }: any = useContext(BetsService);

  const [form] = Form.useForm();

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const [jogos, setJogos] = useState<JogoDTO[]>();

  const [isLoadGames, setIsLoadGames] = useState(false);
  const initialValues = {
    dtaAbertura: undefined,
    dtaFechamento: undefined,
    acumulado: 0,
    apostas: 0,
    garantia: 0,
    garantiaAcumulado: 0,
    qtdMaxJogos: 15,
    percAcumulado: 0,
    pagaAcumulado: 0,
    percPremio: 0,
    txServico: 0,
    flgBonusCartelas: false,
    qtdMinCartelas: 0,
    valor: 0,
    status: 3,
    credito: 0,
  };

  const salvarRodada = async (values: any) => {
    values.valor = fmtValor(values.valor);
    values.acumulado = fmtValor(values.acumulado);
    values.garantia = fmtValor(values.garantia);
    values.percAcumulado = fmtValor(values.percAcumulado);
    values.garantiaAcumulado = fmtValor(values.garantiaAcumulado);
    values.txServico = fmtValor(values.txServico);
    values.percPremio = fmtValor(values.percPremio);
    values.credito = fmtValor(values.credito);
    values.dtaFechamento = fmtDate(values.dtaFechamento);
    values.dtaAbertura = fmtDate(values.dtaAbertura);

    setIsLoading(true);
    if (values && values.id) {
      apiToken
        .put(`rodadas`, values, config)
        .then(() => {
          setIsNew(false);
          reload();
          toast.success('Rodada Atualizada com sucesso');
        })
        .catch((e) => {
          setIsLoading(false);

          if (e.response.data.erros) {
            e.response.data.erros.map((a: any) => {
              toast.error(a.message);
            });
          } else {
            toast.error(mensagemErro(e));
          }
        });
    } else {
      apiToken
        .post(`rodadas`, values, config)
        .then(() => {
          setIsNew(false);
          reload();

          toast.success('Rodada cadastrada com sucesso');
        })
        .catch((e) => {
          setIsLoading(false);

          if (e.response.data.erros) {
            e.response.data.erros.map((a: any) => {
              toast.error(a.message);
            });
          } else {
            toast.error(mensagemErro(e));
          }
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (rodada && rodada.id > 0) {
      form.setFieldsValue({
        id: rodada.id,
        dtaAbertura: moment(rodada.dtaAbertura),
        dtaFechamento: moment(rodada.dtaFechamento),
        acumulado: rodada.acumulado,
        apostas: rodada.apostas,
        garantia: rodada.garantia,
        garantiaAcumulado: rodada.garantiaAcumulado,
        qtdMaxJogos: rodada.qtdMaxJogos,
        pagaAcumulado: rodada.pagaAcumulado,
        percAcumulado: rodada.percAcumulado,
        percPremio: rodada.percPremio,
        txServico: rodada.txServico,
        flgBonusCartelas: rodada.flgBonusCartelas,
        qtdMinCartelas: rodada.qtdMinCartelas,
        valor: rodada.valor,
        status: rodada.status,
        credito: rodada.credito,
      });

      apiToken.get(`rodadas/${rodada.id}/jogos`, config).then((games) => {
        setJogos(games.data);
      });
    }
    setIsLoading(false);
  }, [rodada]);

  const [equipes, setEquipes] = useState<any[]>([]);
  const [campeonatos, setCampeonatos] = useState<CampeonatoDTO[]>([]);

  useEffect(() => {
    setIsLoading(true);

    if (rodada && rodada.id > 0) {
      apiToken.get(`rodadas/${rodada.id}/jogos`, config).then((games) => {
        setJogos(games.data);
      });
    } else {
      setJogos([]);
    }
    setIsLoading(false);
  }, [isLoadGames]);

  useEffect(() => {
    apiToken.get(`equipes`, config).then((times) => {
      const lstEquipe: any[] = [];
      times.data.map((t: any) => {
        lstEquipe.push({ value: t.id, label: t.name });
      });
      setEquipes(lstEquipe);
    });

    apiToken.get(`compeonatos`, config).then((torneios) => {
      const lstTorneios: any[] = [];
      torneios.data.map((t: any) => {
        lstTorneios.push({ value: t.id, label: t.name });
      });
      setCampeonatos(lstTorneios);
    });
  }, []);

  const [showModalJogo, setShowModalJogo] = useState(false);

  return (
    <>
      <div className="dados-acesso">
        <Form
          form={form}
          name="basic"
          initialValues={initialValues}
          layout="vertical"
          onFinish={salvarRodada}
        >
          <Row
            style={{
              background: 'white',
              minWidth: '100%',
            }}
          >
            <Col
              span={24}
              style={{
                display: 'flex',
                marginRight: 10,
              }}
            >
              <Form.Item
                label="ID"
                name="id"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <Input name="id" disabled style={{ width: 85 }} />
              </Form.Item>
              <Form.Item
                label="ABERTURA"
                name="dtaAbertura"
                rules={[{ required: true, message: 'Informe a data' }]}
                style={{
                  marginRight: 20,
                }}
              >
                <DatePicker
                  locale={locale}
                  style={{ width: 170 }}
                  showTime={{ format: 'HH:mm' }}
                  format="DD/MM/YYYY HH:mm"
                />
              </Form.Item>

              <Form.Item
                label="ENCERRAMENTO"
                name="dtaFechamento"
                rules={[{ required: true, message: 'Informe a data' }]}
                style={{
                  marginRight: 20,
                }}
              >
                <DatePicker
                  locale={locale}
                  style={{ width: 170 }}
                  showTime={{ format: 'HH:mm' }}
                  format="DD/MM/YYYY HH:mm"
                />
              </Form.Item>

              <Form.Item
                label="QTD APOSTAS"
                name="apostas"
                rules={[]}
                style={{
                  width: 300,
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  disabled
                  style={{ width: 100, textAlign: 'right' }}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>

              <Form.Item
                label="VLR CARTELA R$"
                name="valor"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalScale={2}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>

              <Form.Item
                label="CRÉDITO R$"
                name="credito"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalScale={2}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: 'flex',
                marginRight: 10,
              }}
            >
              <Form.Item
                label="PRÊMIO % RODADA"
                name="percPremio"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalScale={2}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>
              <Form.Item
                label="PRÊMIO % ACUMULADO"
                name="percAcumulado"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalScale={2}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>

              <Form.Item
                label="PRÊMIO % VALOR SERVIÇO"
                name="txServico"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalScale={2}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>

              <Form.Item
                label="TOTAL JÁ ACUMULADO R$"
                name="acumulado"
                rules={[]}
                style={{
                  width: 120,
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalScale={2}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>

              <Form.Item
                label="GARANTIA ACUMULADO R$"
                name="garantiaAcumulado"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 142, textAlign: 'right' }}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>
              <Form.Item
                label="GARANTIA DA RODADA R$"
                name="garantia"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <NumericFormat
                  style={{ width: 142, textAlign: 'right' }}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  style={{
                    position: 'absolute',
                    top: -161,
                    left: -138,
                    width: 150,
                    height: 31,
                    borderRadius: 0,
                    fontWeight: 'bold',
                  }}
                  htmlType="submit"
                >
                  SALVAR
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: 'flex',
                marginRight: 10,
              }}
            >
              <Form.Item
                label="QTD MAX JOGOS"
                name="qtdMaxJogos"
                rules={[]}
                style={{
                  width: 150,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>
              <Form.Item
                label="PAGA ACUMULADO ?"
                name="pagaAcumulado"
                rules={[{ required: true, message: 'Selecione uma opção' }]}
                style={{
                  marginRight: 20,
                }}
              >
                <Select
                  style={{ width: 150, fontSize: '12px' }}
                  options={[
                    { value: 0, label: 'NÃO' },
                    { value: 1, label: 'SIM' },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                label="CARTELA GRATIS?"
                name="flgBonusCartelas"
                rules={[{ required: true, message: 'Selecione uma opção' }]}
                style={{
                  marginRight: 20,
                }}
              >
                <Select
                  style={{ width: 150, fontSize: '12px' }}
                  options={[
                    { value: false, label: 'NÃO' },
                    { value: true, label: 'SIM' },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                label="QTD MIN CARTELAS"
                name="qtdMinCartelas"
                rules={[]}
                style={{
                  width: 150,
                }}
              >
                <NumericFormat
                  style={{ width: 120, textAlign: 'right' }}
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                />
              </Form.Item>

              <Form.Item
                label="SITUAÇÃO"
                name="status"
                rules={[{ required: true, message: 'Selecione uma opção' }]}
                style={{
                  marginRight: 20,
                }}
              >
                <Select
                  style={{ width: 200, fontSize: '12px' }}
                  options={[
                    { value: 0, label: 'FECHADA' },
                    { value: 1, label: 'ABERTA' },
                    { value: 2, label: 'EM ANDAMENTO' },
                    { value: 3, label: 'PLANAMENTO' },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {rodada.status !== 0 && (
          <div className="box-jogos">
            <div
              style={{
                marginTop: '10px',
                marginBottom: 10,
                fontSize: 18,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ marginTop: 5 }}>JOGOS DA RODADA</div>
              {true && (
                <div>
                  <CadJogo
                    equipes={equipes}
                    campeonatos={campeonatos}
                    reload={() => setIsLoadGames(!isLoadGames)}
                    data={undefined}
                    rodada={rodada.id}
                    show={showModalJogo}
                    setShow={setShowModalJogo}
                  />
                  {rodada.status !== 0 &&
                    jogos &&
                    jogos.length < rodada.qtdMaxJogos && (
                      <ButtonIcon
                        text="ADICIONAR JOGO"
                        position="right"
                        action={() => setShowModalJogo(true)}
                      />
                    )}
                </div>
              )}
            </div>
            <div>
              {jogos?.map((j) => (
                <JogoItem
                  key={j.id}
                  data={j}
                  equipes={equipes}
                  campeonatos={campeonatos}
                  editable={rodada.status !== 0}
                  reload={() => setIsLoadGames(!isLoadGames)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CadRodada;

import axios from 'axios';

const API_URL = "https://www.ecamisa12.com.br/api";

export const  apiBasic = axios.create({
  baseURL: API_URL,
  withCredentials:true,
  auth: {
    username: "app-b3ts", 
    password: "app-b3ts" 
  }
});

export const  apiToken = axios.create({
  baseURL: API_URL,
  withCredentials:false,
  
});

import Jogo from 'components/Jogo';
import JogoDTO from 'dtos/JogoDTO';
import './style.css';
import ApostaDTO from 'dtos/ApostaDTO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { apiToken } from 'api';

import { useState, useContext } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BetsService from 'contexts/BetsService';
import { toast } from 'react-toastify';
import { AxiosRequestConfig } from 'axios';
import { mensagemErro } from 'Utils';

type Props = {
  aposta: ApostaDTO;
  jogos?: JogoDTO[];
  atualizarAposta?: any;
  reload?: any;
  maxPontos: number;
  editable: boolean;
};

const Cartela = ({
  aposta,
  jogos,
  reload,
  atualizarAposta,
  maxPontos,
  editable,
}: Props) => {
  const [show, setShow] = useState(false);

  const { user }: any = useContext(BetsService);

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const deletarAposta = async (aposta: ApostaDTO) => {
    apiToken
      .delete(`apostas/${aposta.id}`, config)
      .then(() => {
        setShow(false);
        reload();
      })
      .catch((e) => {
        setShow(false);
        toast.error(mensagemErro(e));
      });
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', fontSize: 16 }}>
            CONFIRMAÇÃO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 14 }}>
          Confirma exclusão da aposta{' '}
          <strong> {aposta?.id.toString().padStart(6, '0')}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            style={{ color: 'white' }}
            onClick={() => deletarAposta(aposta)}
          >
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="itens">
        <div
          className={
            aposta.pontos.toString() === maxPontos.toString()
              ? 'itens-left-green area-identificacao'
              : 'itens-left-red area-identificacao'
          }
        >

<div className="itemCartelaMaior">
            {aposta.id.toString().padStart(7, '0')}-{aposta.digito}
            
           {aposta.bonus == true && <FontAwesomeIcon
                icon={faGift}
                style={{fontSize: 12, color:"blue", padding: 2, marginLeft: 10}}
                className="icon-alert" title='Cartela Brinde'
              />}
          </div>
          <div className="itemCartela itemCartelaHide">
            ID: <strong>{aposta.hash.substring(1, 10).toUpperCase()}</strong>
          </div>
          <div className="itemCartela ">
            Realizado em:{' '}
            <strong>
              {aposta.data.replace('/' + aposta.data.substring(6, 10), '')}
            </strong>
          </div>
          <div className="itemCartelaMaior">{aposta.pontos} Pontos</div>
          {editable && (<div className="botoes-right">
            
              <FontAwesomeIcon
                icon={faEdit}
                className="icon-edit"
                title="Alterar Palpite"
                onClick={() => atualizarAposta(aposta)}
              />
          
          
              <FontAwesomeIcon
                icon={faTrash}
                className="icon-delete"
                title="Remover palpite"
                onClick={() => setShow(true)}
              />
          </div>
            )}
        </div>
        <div>
          <div
            className="jogos-lista"
          >
            {jogos?.map((j, x) => (
              <Jogo
                key={j.id}
                partida={j}
                palpite={parseInt(aposta.palpites[x])}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cartela;

import { apiToken } from 'api';
import { AxiosRequestConfig } from 'axios';


export const formataMoeda = (valor: number) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valor);
};

export const formataPerc = (valor: number) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(valor);
};

export const fmtValor = (valor: number) => {
  let v = valor ? valor : 0;
  return v.toString().replace('.', '').replace(',', '.');
};


export const fmtValorString = (valor: string) => {
  let v = valor ? valor : '0';
  return v.replace('.', '').replace(',', '.');
};

export const fmtDate = (value: any) => {
  return new Date(
    new Date(value).getTime() - new Date(value).getTimezoneOffset() * 60000
  );
};

export const carregaSaldoUsuario = (
  user: any,
  setIsLoading: any,
  setSaldo: any,
  toast: any
) => {
    const config: AxiosRequestConfig = {    
    headers: { Authorization: `Bearer ${user?.token}` },
  };
  setIsLoading(true);
  apiToken.get(`users/${user?.id}/saldo`, config).then((s) => {
    setSaldo(s.data);
    setIsLoading(false);
  }).catch(e => {
    toast.error(mensagemErro(e));

    setIsLoading(false);
  });;
};


export const mensagemErro = (e: any) =>{
  return e.response ?
  e.response.data.message
              ? e.response.data.message
              : e.response.data.error_description: "Ops! :( Servidor Indisponível"

  return
}
import './style.css';

const Regras = () => {
  return (
    <div>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-container-top">
            <div className="home-left-container-top-left">
              <div className="home-left-container-top-left-top">
                <div className="home-left-container-top-left-top-title">
                  <div className="home-left-container-top-left-top-title-text">
                    <h3>REGRAS GERAIS</h3>
                  </div>
                </div>
              </div>
              <div className="home-left-container-top-left-area">
                <div className="container regras-body">
                  <ul className="regras-body">
                    <div style={{ fontSize: 18, margin: '10px 0px' }} className="td-right">
                      DOS JOGOS{' '}
                    </div>
                    <li>
                      Uma rodada será composta por <strong>15 jogos</strong> de
                      futebol que serão realizados nos finais de semana.
                    </li>
                    <li>
                      Cada rodada será composta de jogos dos campeonatos{' '}
                      <strong>Brasileirão Series A e B</strong> e Jogos
                      internacionais dos campeonatos:{' '}
                      <strong>
                        Alemão, Espanhol, Francês, Inglês, Italiano e Eurocopa
                      </strong>
                      .
                    </li>

                    <div style={{ fontSize: 18, margin: '10px 0px' }} className="td-right">
                      DOS PALPITES{' '}
                    </div>
                    <li>
                      Cada jogador poderá adquirir quantas cartelas desejar até
                      zerar o saldo da sua carteira.
                    </li>
                    <li>
                      O jogador deverá escolher para cada um dos jogos da
                      rodada, o vencedor do confontro ou se haverá empate.{' '}
                      <strong>VENCEDOR ou EMPATE</strong>, sem considerar
                      possível prorrogação.
                    </li>
                    <li>
                      Cada jogo com resultado correto valerá{' '}
                      <strong>1 ponto</strong> na cartela.
                    </li>
                    <li>
                      Caso algum jogo venha a ser cancelado,
                      <strong>
                        {' '}
                        a pontuação será nula para todas as cartelas.
                      </strong>
                    </li>

                    <div style={{ fontSize: 18, margin: '10px 0px' }} className="td-right">
                      DAS PREMIAÇÕES{' '}
                    </div>
                    <li>
                      O palpite que alcançar <strong>mais pontos</strong> na
                      rodada levará o <strong>prêmio da rodada</strong>.
                    </li>
                    <li>
                      O palpite que alcançar mais pontos e atingir{' '}
                      <strong>13 pontos ou + </strong> na rodada levará o{' '}
                      <strong>prêmio acumulado</strong>.
                    </li>
                    <li>
                      Em caso de empate, o <strong>prêmio será divido</strong>{' '}
                      entre os jogadores com a maior pontuação.
                    </li>

                    <div style={{ fontSize: 18, margin: '10px 0px' }} className="td-right">
                      DAS RODADAS DO ACUMULADO{' '}
                    </div>
                    <li>
                      O prêmio acumulado será pago obrigatóriamente no fim do
                      Primeiro Turno do Brasileirão Serie A (<strong>19ª RODADA do brasileirão</strong>).
                    </li>
                    <li>
                      O prêmio acumulado será pago obrigatóriamente no fim do
                      Segundo Turno do Brasileirão Serie A (<strong>38ª RODADA do brasileirão</strong>).
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-right-container">
          <div className="home-right-container-top">PRÊMIOS</div>
          <div className="regras-body-left">
            <table>
              <tbody>
                <tr>
                  <td className="td-right">
                    <strong>PRÊMIO DA RODADA</strong>
                  </td>
                </tr>
                <tr>
                  <td className="td-left">
                    Coresponderá ao <strong>70% do valor arrecadado </strong>na
                    rodada e será pago ao <strong>maior pontuador</strong> da
                    rodada.
                  </td>
                </tr>
                <tr>
                  <td className="td-left">
                    <strong>PRÊMIO DA RODADA GARANTIDO</strong>
                    <br />O prêmio da rodada será garantido no valor mínimo de
                    <strong> R$ 100 (Cem Reais).</strong>
                  </td>
                </tr>
                <tr>
                  <td className="td-right">
                    <strong>PRÊMIO ACUMULADO</strong>
                  </td>
                </tr>
                <tr>
                  <td className="td-left">
                    Corresponde ao valor total acumulado até a rodada atual.
                    <br />A cada rodada <strong>
                      20% do valor arrecadado
                    </strong>{' '}
                    será destinado ao prêmio acumulado.
                  </td>
                </tr>

                <tr>
                  <td className="td-left">
                    <strong>PRÊMIO ACUMULADO GARANTIDO</strong>
                    <br />O prêmio acumnulado será garantido no valor mínimo de
                    <strong> R$ 500 (Quinhentos Reais).</strong>
                  </td>
                </tr>
                <tr>
                  <td className="td-right">
                    <strong>PRÊMIO DE CONSOLAÇÃO</strong>
                  </td>
                </tr>
                <tr>
                  <td className="td-left">                    
                    Somente os <strong>palpites pagos</strong> concorrerão ao PRÊMIO DE CONSOLAÇÃO.<br/><br/>
                    O palpite que menos pontuar terá o valor do <strong>palpite devolvido em bônus</strong>, que obrigatoriamente deverá ser utilizado na aquisição de novos palpites.                    
                    <br /> Em caso de empate, será premiado o palpite realizado primeiro (menor número).
                    <br/><br/><strong>O Valor NÃO poderá ser sacado.</strong>
                  </td>
                </tr>

                <tr>
                  <td className="td-right">
                    <strong>PALPITE BONUS</strong>
                  </td>
                </tr>
                <tr>
                  <td className="td-left">
                    As rodadas terão palpites bonus. <br/>
                    <br />
                    <p style={{fontSize:18, border:'2px dotted orange', textAlign: 'center', margin: 10}}><strong>2 palpites = 1 palpite bonus</strong></p>
                    <br />
                    <li>A cada 2 palpites, o jogador <strong>ganhará 1 palpite de bonus.</strong></li>
                    <li>Caso um dos 2 primeiros palpites seja cancelado, o palpite bonus <strong>será excluído automaticamente</strong> pelo sistema.<br/></li>
                    <li>O palpite bonus <strong>não será válido para rodadas futuras.</strong><br/></li>
                    <li>Não será ofertado palpite bonus nas rodadas do bolão acumulado.</li>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regras;

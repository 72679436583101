import { apiToken } from 'api';
import BetsService from 'contexts/BetsService';
import RodadaItem from 'components/RodadaItem';
import RodadaDTO from 'dtos/RodadaDTO';
import { useContext, useEffect, useState } from 'react';
import './style.css';
import ButtonIcon from 'components/ButtonIcon';
import CadRodada from 'pages/CadRodada';
import { formatInTimeZone } from 'date-fns-tz';
import PageConfig from 'types/PageConfig';
import Navegador from 'components/Navegador';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AxiosRequestConfig } from 'axios';
import { carregaSaldoUsuario, mensagemErro } from 'Utils';

export const STATUS = ['ENCERRADA', 'ABERTA', 'EM ANDAMENTO', 'PLANEJADA'];

const Rodadas = () => {
  const { user }: any = useContext(BetsService);
  const { setIsLoading, toast, setSaldo }: any = useContext(BetsService);
  const [isReloading, setIsReloading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [rodadas, setRodadas] = useState<RodadaDTO[]>();
  const [page, setPage] = useState<number>(0);
  const [pageConfig, setPageConfig] = useState<PageConfig>();

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const rodadaVazia = {
    id: 0,
    dtaAbertura: formatInTimeZone(
      new Date(),
      'America/Fortaleza',
      'dd/MM/yyyy HH:mm:ss'
    ),
    dtaFechamento: formatInTimeZone(
      new Date(),
      'America/Fortaleza',
      'dd/MM/yyyy HH:mm:ss'
    ),
    acumulado: 0,
    apostas: 0,
    garantia: 0,
    garantiaAcumulado: 0,
    pagaAcumulado: 0,
    percAcumulado: 0,
    percPremio: 0,
    txServico: 0,
    valor: 0,
    qtdMaxJogos: 15,
    flgBonusCartelas: false,
    qtdMinCartelas: 0,
    jogos: [],
    status: 3,
    resultado: '',
    credito: 0,
  };
  const [rodada, setRodada] = useState<RodadaDTO>(rodadaVazia);

  useEffect(() => {
    carregaSaldoUsuario(user, setIsLoading, setSaldo, toast);

    setIsLoading(true);

    apiToken
      .get(`rodadas?page=${page}`, config)
      .then((rounds) => {
        setRodadas(rounds.data.content);

        const p: number[] = [];
        for (let index = 0; index < rounds.data.totalPages; index++) {
          p.push(index);
        }
        setPageConfig({
          totalElements: rounds.data.totalElements,
          totalElementsPage: rounds.data.numberOfElements,
          totalPages: rounds.data.totalPages,
          currentPage: rounds.data.pageable.pageNumber,
          pages: p,
        });

        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));

        setIsLoading(false);
      });
  }, [isReloading, page]);

  const reload = () => {
    setIsReloading(!isReloading);
  };

  const cancelarEdicao = async () => {
    setIsNew(false);
    reload();
  };

  const editRodada = (round: RodadaDTO) => {
    setRodada(round);
    setIsNew(true);
  };

  const novaRodada = () => {
    setRodada(rodadaVazia);
    setIsNew(true);
  };

  const deletarRodada = async (rodada: RodadaDTO) => {
    apiToken
      .delete(`rodadas/${rodada.id}`, config)
      .then(() => {
        setShow(false);
        toast.success('Rodada excluída com sucesso !!!');
        reload();
      })
      .catch((e) => {
        setShow(false);
        toast.error(mensagemErro(e));

      });
  };

  const [show, setShow] = useState(false);

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', fontSize: 16 }}>
            CONFIRMAÇÃO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 14 }}>
          Confirma exclusão da Rodada{' '}
          <strong> {rodada?.id.toString().padStart(6, '0')}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            style={{ color: 'white' }}
            onClick={() => deletarRodada(rodada)}
          >
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <div className="home-container">
          <div className="home-left-container">
            <div className="home-left-container-top">
              <div className="home-left-container-top-left">
                <div className="home-left-container-top-left-top">
                  <div className="home-left-container-top-left-top-title">
                    <div className="home-left-container-top-left-top-title-text">
                      <h3>RODADAS</h3>
                      <h6>Configurações Gerais</h6>
                    </div>
                  </div>
                  <div className="home-left-container-top-right">
                    {!isNew && (
                      <ButtonIcon
                        text="NOVO"
                        action={() => novaRodada()}
                        position="right"
                      />
                    )}
                    {isNew && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <ButtonIcon
                          text="CANCELAR"
                          action={() => cancelarEdicao()}
                          color="bg-danger btn-secondary"
                        />
                        <div style={{ width: '160px' }} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="home-left-container-top-left-area">
                  {!isNew && (
                    <div className="container rodadas-body">
                      {rodadas &&
                        rodadas?.map((r) => (
                          <RodadaItem
                            key={r.id}
                            data={r}
                            onEdit={() => editRodada(r)}
                            onDelete={() => {
                              setRodada(r);
                              setShow(true);
                            }}
                          />
                        ))}
                    </div>
                  )}
                  {!isNew && (
                    <Navegador
                      pageConfig={pageConfig}
                      setPage={setPage}
                      elemento="Rodadas"
                    />
                  )}
                  {isNew && (
                    <div className="container rodadas-body">
                      <CadRodada
                        setIsNew={setIsNew}
                        rodada={rodada}
                        setRodada={setRodada}
                        reload={reload}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {false && (
            <div className="home-right-container">
              <div className="home-right-container-top">PRÊMIOS</div>
              <div className="regras-body-left"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Rodadas;

import { useState, useContext, useEffect } from 'react';
import './style.css';
import { apiToken } from 'api';
import BetsService from 'contexts/BetsService';
import { Form, Row, Col, Input, Button, Select, DatePicker } from 'antd';
import { mensagemErro } from 'Utils';
import { AxiosRequestConfig } from 'axios';
import EquipeDTO from 'dtos/EquipeDTO';

type Props = {
  setIsNew: any;
  equipe?: EquipeDTO;
  reload: any
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const CadEquipe = ({ setIsNew, equipe, reload }: Props) => {
  const { user, setIsLoading, toast }: any = useContext(BetsService);

  const [form] = Form.useForm();

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const initialValues = {
    name: undefined,
    sigla: undefined,
    logo: undefined,
    uf: undefined,
  };

  const salvarEquipe = async (values: any) => {
    setIsLoading(true);
    if (values && values.id) {
      apiToken
        .put(`equipes`, values, config)
        .then(() => {
          setIsNew(false);
          reload();
          setIsLoading(false);
          toast.success('Equipe Atualizada com sucesso');
        })
        .catch((e) => {
          setIsLoading(false);

          if (e.response.data.erros) {
            e.response.data.erros.map((a: any) => {
              toast.error(a.message);
            });
          } else {
            toast.error(mensagemErro(e));
          }
        });
    } else {
      apiToken
        .post(`equipes`, values, config)
        .then(() => {
          setIsNew(false);
          reload();
          setIsLoading(false);

          toast.success('Equipe cadastrada com sucesso');
        })
        .catch((e) => {
          setIsLoading(false);

          if (e.response.data.erros) {
            e.response.data.erros.map((a: any) => {
              toast.error(a.message);
            });
          } else {
            toast.error(mensagemErro(e));
          }
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    form.setFieldsValue(equipe ? equipe :{
      name: undefined,
      sigla: undefined,
      logo: undefined,
      uf: undefined,
      divisao: undefined,
    svg: undefined
    });

    setIsLoading(false);
  }, []);

  return (
    <>
      <div className="dados-acesso">
        <Form
          form={form}
          name="basic"
          initialValues={initialValues}
          layout="vertical"
          onFinish={salvarEquipe}
        >
          <Row
            style={{
              background: 'white',
              minWidth: '100%',
            }}
          >
            <Col
              span={24}
              style={{
                display: 'flex',
                marginRight: 10,
              }}
            >
              <Form.Item
                label="ID"
                name="id"
                rules={[]}
                style={{
                  marginRight: 20,
                }}
              >
                <Input name="id" disabled style={{ width: 70 }} />
              </Form.Item>
              <Form.Item
                label="EQUIPE"
                name="name"
                rules={[
                  { required: true, message: 'Informe o nome da equipe' },
                ]}
                style={{
                  marginRight: 20,
                  width: 300
                }}
              >
                <Input name="name" maxLength={100}/>
              </Form.Item>
              <Form.Item
                label="SIGLA"
                name="sigla"
                rules={[
                  { required: true, message: 'Informe a sigla da equipe' },
                ]}
                style={{
                  marginRight: 20,
                  width: 100
                }}
              >
                <Input name="sigla" maxLength={10}/>
              </Form.Item>
              <Form.Item
                label="UF"
                name="uf"
                rules={[
                  { required: true, message: 'Informe a UF' },
                ]}
                style={{
                  marginRight: 20,
                  width: 100
                }}
              >
                <Input name="uf" maxLength={10}/>
              </Form.Item>
              <Form.Item
                label="DIVISÃO"
                name="divisao"
                rules={[
                  { required: true, message: 'Informe a DIVISÃO' },
                ]}
                style={{
                  marginRight: 20,
                  width: 100
                }}
              >
                <Input name="uf" maxLength={5}/>
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                display: 'flex',
                marginRight: 10,
              }}
            >
               <Form.Item
                label="LOGO"
                name="logo"
                rules={[
                  { required: true, message: 'Informe a Logo da equipe' },
                ]}
                style={{
                  marginRight: 20,
                  width: '100%'
                }}
              >
                <Input name="logo" maxLength={100} />
              </Form.Item>
            <Form.Item
                label="SVG"
                name="svg"
                rules={[
                  { required: true, message: 'Informe o SVG da logo' },
                ]}
                style={{
                  marginRight: 20,
                  width: '100%'
                }}
              >
                <Input name="svg" maxLength={100}/>
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                display: 'flex',
                marginRight: 10,
              }}
            >
              <Form.Item {...tailLayout}>               
                <Button
                  type="primary"
                  style={{
                    position:'absolute',
                    top: -265,
                    left: 750,
                    width: 150,
                    height: 31,
                    borderRadius: 0,
                    fontWeight: 'bold',
                  }}
                  htmlType="submit"
                >
                  SALVAR
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default CadEquipe;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatInTimeZone } from 'date-fns-tz';
import RodadaDTO from 'dtos/RodadaDTO';
import { STATUS } from 'pages/Rodadas';
import { formataPerc, formataMoeda } from 'Utils';
import './style.css';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

type Props = {
  data: RodadaDTO;
  onEdit?: any;
  onDelete?: any;
};

const RodadaItem = ({ data, onEdit, onDelete }: Props) => {
  const exibeDelete = data.status > 0 && data.apostas === 0;
  return (
    <>
      <div
        className={`${data.status == 1 ? 'linha' : 'linha-inativa'}`}
        key={data.id}
      >
        <div style={{ width: '5%' }}>
          <div className="label">ID</div>
          <div>{data.id.toString().padStart(3, '0')}</div>
        </div>
        <div style={{ width: '15%' }}>
          <div className="label">ABERTURA</div>

          <div>
            {formatInTimeZone(
              new Date(data.dtaAbertura),
              'America/Fortaleza',
              'dd/MM/yyyy HH:mm'
            )}
          </div>
        </div>
        <div style={{ width: '15%' }}>
          <div className="label">FECHAMENTO</div>

          <div>
            {formatInTimeZone(
              new Date(data.dtaFechamento),
              'America/Fortaleza',
              'dd/MM/yyyy HH:mm'
            )}
          </div>
        </div>
        <div style={{ width: '5%', textAlign: 'right' }}>
          <div className="label">CARTELAS</div>
          <div>{data.apostas}</div>
        </div>
        <div style={{ width: '10%', textAlign: 'right' }}>
          <div className="label">PREÇO UNIT</div>
          <div>{formataMoeda(data.valor)}</div>
        </div>
        <div style={{ width: '10%', textAlign: 'right' }}>
          <div className="label">TOTAL</div>
          <div> {formataMoeda(data.valor * data.apostas)}</div>
        </div>

        <div style={{ width: '10%', textAlign: 'right' }}>
          <div className="label">ACUMULADO</div>
          <div>{formataMoeda(data.acumulado)}</div>
        </div>
        <div style={{ width: '10%', textAlign: 'right' }}>
          <div className="label">PREMIO FINAL</div>
          <div> {formataMoeda(data.valor * data.apostas + data.acumulado)}</div>
        </div>
        <div style={{ width: '15%', textAlign: 'right' }}>
          <div className="label">SITUAÇÃO</div>
          <div
            className={`${data.status == 0 ? 'status-closed' : 'status-open'}`}
          >
            {STATUS[data.status]}
          </div>
        </div>

        <div className={`${exibeDelete?"position-icons":"position-icons-one"}`}>
          <FontAwesomeIcon
            style={{ paddingRight: '15px' }}
            icon={faEdit}
            className="icon-edit"
            title="Alterar Rodada"
            onClick={() => onEdit()}
          />

          {exibeDelete && (
            <FontAwesomeIcon
              icon={faTrash}
              className="icon-delete"
              onClick={() => onDelete()}
              title="Remover Rodada"
            />
          )}
        </div>
      </div>
    </>
  );
};
export default RodadaItem;

import { Form } from 'antd';
import { apiToken } from 'api';
import { AxiosRequestConfig } from 'axios';
import ButtonIcon from 'components/ButtonIcon';
import Lancamento from 'components/Lancamento';
import Navegador from 'components/Navegador';
import BetsService from 'contexts/BetsService';

import LancamentoDTO from 'dtos/LancamentoDTO';
import PixDTO from 'dtos/PixDTO';
import { useContext, useEffect, useState } from 'react';
import PageConfig from 'types/PageConfig';
import { mensagemErro } from 'Utils';
import './style.css';

const PixPage = () => {
  const { user }: any = useContext(BetsService);
  const [pageConfig, setPageConfig] = useState<PageConfig>();
  const [isReloading, setIsReloading] = useState(false);
  const [page, setPage] = useState<number>(0);

  const tailLayout = {
    wrapperCol: { span: 24 },
  };
  const [formData] = Form.useForm();

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const [pix, setPix] = useState<PixDTO>();
  const { setIsLoading, toast }: any = useContext(BetsService);

  const [lancamentos, setLancamentos] = useState<LancamentoDTO[]>([]);

  const subscribe = () => {
    setIsLoading(true);
    apiToken
      .get(`webhook/subscribe`, config)
      .then(() => {
        toast.success('Webhook ativado com sucesso!!!');
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));
        setIsLoading(false);
      });
  };

  const unsubscribe = () => {
    setIsLoading(true);
    apiToken
      .get(`webhook/unsubscribe`, config)
      .then(() => {
        toast.success('Webhook desativado com sucesso!!!');
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    apiToken
      .get(`pix/pendentes?page=${page}`, config)
      .then((pixes) => {
        setLancamentos(pixes.data.content);

        const p: number[] = [];
        for (let index = 0; index < pixes.data.totalPages; index++) {
          p.push(index);
        }
        setPageConfig({
          totalElements: pixes.data.totalElements,
          totalElementsPage: pixes.data.numberOfElements,
          totalPages: pixes.data.totalPages,
          currentPage: pixes.data.pageable.pageNumber,
          pages: p,
        });

        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));
        setIsLoading(false);
      });
  }, [isReloading, page]);

  const reload = () => {
    setIsReloading(!isReloading);
  };

  return (
    <div>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-container-top">
            <div className="home-left-container-top-left">
              <div className="home-left-container-top-left-top">
                <div className="home-left-container-top-left-top-title">
                  <div className="home-left-container-top-left-top-title-text">
                    <h3>PIX</h3>
                    <h6>Gestão de Depósitos</h6>
                  </div>
                </div>
                <div className="home-left-container-top-right">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: 350,
                    }}
                  >
                    <ButtonIcon
                      text="ATIVAR WEBHOOK"
                      action={() => subscribe()}
                      position="right"
                    />
                    <ButtonIcon
                      text="DESATIVAR WEBHOOK"
                      action={() => unsubscribe()}
                      position="right"
                    />
                  </div>
                </div>
              </div>
              <div className="home-left-container-top-left-area">
                <div className="container pix-body">
                  <div className="container pix-body">
                    {lancamentos &&
                      lancamentos?.map((l) => {
                        return (
                          <Lancamento
                            data={l}
                            config={config}
                            reload={reload}
                            carteira={false}
                          />
                        );
                      })}

                    {!lancamentos ||
                      (lancamentos.length === 0 && (
                        <div
                          className="box-navigator"
                          style={{
                            fontSize: 16,
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'red',
                            textAlign: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          <div>
                            Não existem pix para confirmação no momento.
                          </div>
                        </div>
                      ))}
                  </div>

                  <div style={{ paddingTop: 5 }}>
                    <Navegador
                      pageConfig={pageConfig}
                      setPage={setPage}
                      elemento="Depósitos"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PixPage;

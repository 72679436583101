import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext, useEffect } from 'react';
import { format } from 'date-fns-tz';
import RodadaDTO from 'dtos/RodadaDTO';
import {
  faExternalLink,
  faSoccerBall,
} from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { mensagemErro } from 'Utils';
import BetsService from 'contexts/BetsService';
import { apiBasic } from 'api';
import { Statistic } from 'antd';

type Props = {
  reload?: any;
};

const JogosRodada = ({reload}: Props) => {
  const { setIsLoading, toast, rodada, jogos, setJogos }: any =
    useContext(BetsService);
  const [loadGames, setLoadGame] = useState(false);

  const { Countdown } = Statistic;

  const ESPERA = 1000 * 61;
  const [tempo, setTempo] = useState(Date.now() + ESPERA);

  function onFinish() {
    setTempo(Date.now() + ESPERA);
    setLoadGame(!loadGames);
    reload();
  }

  useEffect(() => {
    const carregarDados = async () => {
      setIsLoading(true);
      if (rodada) {
      apiBasic
        .get(`rodadas/${rodada.id}/jogos`)
        .then((j) => {
          setJogos(j.data);
          setIsLoading(false);
          console.log("atualizando jogos !!!");
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
      }
    };
    carregarDados();
  }, [loadGames ]);

  return (
    <div className="big-container-min">
      <div className="container-min">
        <div className="home-right-container-top">
          JOGOS DA {rodada?.id}ª RODADA
        </div>
        {jogos && (
          <>
            {jogos?.map((j: any, x: any) => (
              <div key={x} className="container-item-nova-aposta-min">
                <div className="container-item-nova-aposta-ordem">
                  j{j.ordem}
                  {rodada.status == 2 && j.status == 1 && (
                    <FontAwesomeIcon
                      icon={faSoccerBall}
                      className="icon-bola"
                      title="Visitar site do Jogo"
                    />
                  )}
                </div>
                <div
                  className="nao-selecionado"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <img alt="" src={j.timeCasa.logo} title={j.timeCasa.name} />
                  <div style={{ fontSize: '10px' }}>{j.timeCasa.sigla}</div>
                </div>

                <div className="nao-selecionado">
                  {j.status > 0 && (
                    <div style={{ textAlign: 'center', marginTop: -5 }}>
                      {j.placar}
                    </div>
                  )}
                  {j.status === 0 && (
                    <div
                      style={{ width: 50, textAlign: 'center', marginTop: -5 }}
                    >
                      X
                    </div>
                  )}
                </div>
                <div
                  className="nao-selecionado"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <img alt="" src={j.timeFora.logo} title={j.timeFora.name} />
                  <div style={{ fontSize: '10px' }}>{j.timeFora.sigla}</div>
                </div>

                <div className="container-item-nova-aposta-local">
                  {format(
                    rodada ? new Date(j.dataEvento) : new Date(),
                    'dd/MM/yyyy HH:mm'
                  )}
                  <br />
                  <div style={{ fontWeight: 'normal', fontSize: '8px' }}>
                    {j.campeonato.name}
                  </div>
                </div>
                <div style={{ marginTop: -15 }}>
                  <a href={j.link} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      className="icon-edit"
                      title="Visitar site do Jogo"
                    />
                  </a>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <Countdown
        title=""
        value={tempo}
        format="ss"
        onFinish={onFinish}
        style={{
          width: 0,
          height: 0,
          fontSize: '5px',
          visibility: 'hidden',
          display: 'inline',
        }}
      />
    </div>
  );
};

export default JogosRodada;

import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faXmark,
  faClock,
  IconDefinition,
  faBan,
  faTrophy,
  faL,
  faIceCream,
  faGift,
} from '@fortawesome/free-solid-svg-icons';
import JogoDTO from 'dtos/JogoDTO';
import ApostaDTO from 'dtos/ApostaDTO';

type Props = {
  aposta: ApostaDTO;
  jogos?: JogoDTO[];
  atualizarAposta?: any;
  reload?: any;
  maxPontos: number;
  indice: number;
  rodadaStatus: number;
};

const RankingItem = ({
  rodadaStatus,
  jogos,
  indice,
  aposta,
  maxPontos,
}: Props) => {
  const PONTOS_PREMIACAO = aposta.rodada.qtdMaxJogos == 15 ? maxPontos - 1 : 7;
  const max = aposta ? aposta.rodada.qtdMaxJogos - 1 : 12;
  const exibePalpite = (indice: number) => {
    const casa = jogos && jogos[indice] ? jogos[indice].timeCasa.sigla : '';
    const fora = jogos && jogos[indice] ? jogos[indice].timeFora.sigla : '';
    const palpite = parseInt(aposta.palpites[indice]);

    switch (palpite) {
      case 0:
        return 'emp';
      case 1:
        return casa;
      case 2:
        return fora;
    }
  };

  const mensagem = (jogos: any, indice: number) => {
    if (indice <= max && jogos && jogos.length > 0) {
      const mensagens = [
        'Aguarde início do jogo',
        'Jogo em andamento',
        'Jogo Encerrado',
        'Jogo Cancelado',
      ];
      let a = mensagens[jogos[indice].status];
      return a;
    }
    return '';
  };

  const classeIcone = (jogos: any, x: number) => {
    if (jogos && jogos.length > 0) {
      if (jogos[x] && jogos[x].status > 0) {
        if (aposta.palpites[x] === aposta.rodada.resultado[x]) {
          if (jogos[x].status < 3) {
            return 'certo';
          } else {
            return 'canceled';
          }
        } else {
          return 'erro';
        }
      } else {
        return 'aguardando';
      }
    }
    return 'canceled';
  };

  const getIcone = (jogos: any, x: number): IconDefinition => {
    if (jogos && jogos.length > 0) {
      if (jogos[x] && jogos[x].status > 0) {
        if (aposta.palpites[x] === aposta.rodada.resultado[x]) {
          if (jogos[x].status < 3) {
            return faCheck;
          } else {
            return faBan;
          }
        } else {
          return faXmark;
        }
      } else {
        return faClock;
      }
    }
    return faClock;
  };

  return (
    <>
      <div
        className={
          rodadaStatus == 2 || rodadaStatus == 0
            ? 'ranking-itens-max'
            : 'ranking-itens'
        }
      >
        <div
          className={
            aposta.pontos > PONTOS_PREMIACAO && aposta.pontos >= maxPontos
              ? 'ranking-itens-left ranking-itens-left-green'
              : 'ranking-itens-left ranking-itens-left-red'
          }
        >
          <div
            title={
              aposta.pontos > PONTOS_PREMIACAO &&
              aposta.pontos >= maxPontos &&
              rodadaStatus === 0
                ? 'PREMIO DA RODADA'
                : ''
            }
          >
            {aposta.pontos > PONTOS_PREMIACAO && aposta.pontos >= maxPontos
              ? 1
              : indice}
            º{' '}
            {aposta.pontos > PONTOS_PREMIACAO &&
              aposta.pontos >= maxPontos &&
              rodadaStatus === 0 && (
                <FontAwesomeIcon className="iconeTrofeu" icon={faTrophy} />
              )}
            {aposta.rodada.id > 12 &&
              rodadaStatus === 0 &&
              aposta.premioBonus && (
                <FontAwesomeIcon
                  title="PREMIO DE CONSOLAÇÃO"
                  className="iconeSorvete"
                  icon={faIceCream}
                />
              )}
          </div>
        </div>

        <div className="ranking-player">
          <p
            className={
              rodadaStatus == 2 || rodadaStatus == 0
                ? 'ranking-player-id-max'
                : 'ranking-player-id'
            }
          >
            TKT-{aposta.id.toString().padStart(6, '0')}
            {'-'}
            {aposta.digito.toString()}
            {aposta.bonus == true && (
              <FontAwesomeIcon
                icon={faGift}
                style={{ fontSize: 12, color: 'darkgreen', paddingLeft: 10 }}
                className="icon-alert"
                title="Cartela Brinde"
              />
            )}
          </p>
          {aposta.apostador.nome}
        </div>
        <div className="ranking-jogos grid-items">
          {aposta.palpites &&
            aposta.palpites
              .substring(0, aposta.rodada.qtdMaxJogos)
              .split('')
              .map((j, x) => (
                <>
                  {
                    <div className="ranking-jogos-item" key={x}>
                      <FontAwesomeIcon
                        title={mensagem(jogos, x)}
                        icon={getIcone(jogos, x)}
                        className={classeIcone(jogos, x)}
                      />

                      {(rodadaStatus == 2 || rodadaStatus == 0) && (
                        <div
                          style={{
                            marginTop: '-5px',
                            fontSize: 11,
                            fontWeight: 'normal',
                          }}
                        >
                          {exibePalpite(x)}
                        </div>
                      )}
                    </div>
                  }
                </>
              ))}
        </div>
        <div className="ranking-jogos">
          <div
            className={
              aposta.pontos > PONTOS_PREMIACAO && aposta.pontos >= maxPontos
                ? 'ranking-itens-right ranking-itens-left-green'
                : 'ranking-itens-right ranking-itens-left-red'
            }
          >
            {aposta.pontos}{' '}
            <div
              className={
                rodadaStatus == 2 || rodadaStatus == 0 ? 'pts-max' : 'pts'
              }
            >
              Pts
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RankingItem;

import { useState, useEffect, useContext } from 'react';
import './style.css';
import { apiToken } from 'api';
import JogoDTO from 'dtos/JogoDTO';
import RankingItem from 'components/RankingItem';
import PageConfig from 'types/PageConfig';
import ResumoRodadaDTO from 'types/ResumoRodadaDTO';
import ResumoRodada from 'components/ResumoRodada';
import BetsService from 'contexts/BetsService';
import Navegador from 'components/Navegador';
import ApostaDTO from 'dtos/ApostaDTO';
import { AxiosRequestConfig } from 'axios';
import JogosRodada from 'components/JogosRodada';
import SelectRodada from 'components/SelectRodada';
import { STATUS } from 'pages/Rodadas';
import { carregaSaldoUsuario, mensagemErro } from 'Utils';

const Ranking = () => {
  const [exibeNavegacao, setExibeNavegacao] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [pageConfig, setPageConfig] = useState<PageConfig>();

  const [resumo, setResumo] = useState<ResumoRodadaDTO>({
    totalArrecadado: 0,
    valorUnitario: 0,
    totalServico: 0,
    valorGarantia: 0,
    valorPremiacao: 0,
    valorGarantiaAcumulado: 0,
    garantiaAcumulado: 0,
    totalRodada: 0,
    qtdCartelas: 0,
    acumulado: 0,
    acumuladoRodada: 0,
    maxPontos: 0,
    percAcumulado: 0,
    percPremio: 0,
    percServico: 0,
  });

  const [isReloading, setIsReloading] = useState(false);
  const [apostas, setApostas] = useState<ApostaDTO[]>();


  const { setIsLoading, toast, user, rodada, setSaldo,jogos }: any = useContext(BetsService);
  
  const getRegistros = () => {
    return (rodada.status === 1 ? 38 : 29)
  }

  useEffect(() => {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${user?.token}` },
    };

  
    const carregarDados = async () => {
      carregaSaldoUsuario(user, setIsLoading, setSaldo, toast);

      setIsLoading(true);
      apiToken
        .get(`apostas/${rodada.id}/rodada?page=${page}&size=${getRegistros()}`, config)
        .then((a) => {
          const rApostas:ApostaDTO[]= a.data.content;
          setExibeNavegacao(rApostas.length > 0);
          if (rApostas && rApostas.length > 0) {
            let achou = false;
            let id = rApostas.length - 1;
            while (!achou && id > -1) {
              if (!rApostas[id].bonus) {
                 rApostas[id].premioBonus = true;
                 achou = true;
               } else {
                rApostas[id].premioBonus = false;
               }
               id = id -1;
            }
          }
          setApostas(rApostas);
          const p = [];
          for (let index = 0; index < a.data.totalPages; index++) {
            p.push(index);
          }
          setPageConfig({
            totalElements: a.data.totalElements,
            totalElementsPage: a.data.numberOfElements,
            totalPages: a.data.totalPages,
            currentPage: a.data.pageable.pageNumber,
            pages: p,
          });
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });

      apiToken
        .get(`rodadas/${rodada.id}/resumo`, config)
        .then((resumoRodada) => {
          setResumo(resumoRodada.data);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
    };
    if (rodada) {
      carregarDados();      
    }
  }, [isReloading, page, rodada]);

  const reload = () => {
    setIsReloading(!isReloading);
  };


  return (
    <div>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-container-top">
            <div className="home-left-container-top-left">
              <div className="home-left-container-top-left-top">
                <div className="home-left-container-top-left-top-title">
                  <div className="home-left-container-top-left-top-title-text">
                    <h3>RANKING</h3>
                    <h6>
                      {' '}
                      {rodada?.id}ª RODADA{' '}
                      {rodada?.status
                        ? STATUS[rodada ? rodada.status : 0]
                        : 'ENCERRADA'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="home-left-container-top-left-area">
                <div className="container">
                  <div className="lista-jogos-topo jogos-lista">
                    <div className="lista-jogos-topo-nome">PLAYER</div>
                    <div className="topo-nomes">
                      {jogos?.map((j: JogoDTO) => (
                        <div
                          className={`ranking-jogo-container${
                            rodada ? rodada.qtdMaxJogos : 12
                          }`}
                          key={j.id}
                        >
                          <div>
                            {j.timeCasa.sigla} x {j.timeFora.sigla}
                          </div>
                          <div className="jogo-container-foot">
                            {j.placar && j.status > 0
                              ? j.status === 3
                                ? '---'
                                : j.placar
                              : '***'}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {jogos &&
                    apostas &&
                    apostas.map((c, x) => (
                      <RankingItem
                        key={c.id}                        
                        indice={
                          x +
                          1 +
                          (pageConfig ? pageConfig.currentPage : 0) *
                          getRegistros()
                        }
                        maxPontos={resumo.maxPontos}
                        aposta={c}
                        reload={reload}
                        jogos={jogos}
                        rodadaStatus={rodada?.status}
                      />
                    ))}
                  {!exibeNavegacao && (
                    <div
                      className="box-navigator"
                      style={{
                        fontSize: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'red',
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      <div>Não existem apostas até o momento.</div>
                    </div>
                  )}
                  {exibeNavegacao && (
                    <div style={{ paddingTop: 20 }}>
                      <Navegador
                        pageConfig={pageConfig}
                        setPage={setPage}
                        elemento="Cartelas"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-right-container">
          {user && <SelectRodada />}
          <JogosRodada reload={reload} />
          <ResumoRodada resumo={resumo} />
        </div>
      </div>
    </div>
  );
};

export default Ranking;

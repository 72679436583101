import React, { useState, useContext, useEffect } from 'react';

import { apiToken, apiBasic } from 'api';
import { toast } from 'react-toastify';
import BetsService from 'contexts/BetsService';
import { formataMoeda, mensagemErro } from 'Utils';
import RodadaDTO from 'dtos/RodadaDTO';
import ResumoRodadaDTO from 'types/ResumoRodadaDTO';

const GridPremios = () => {
  const { setIsLoading, toast }: any = useContext(BetsService);

  const [resumo, setResumo] = useState<ResumoRodadaDTO>({
    totalArrecadado: 0,
    valorUnitario: 0,
    totalServico: 0,
    valorGarantia: 0,
    valorPremiacao: 0,
    valorGarantiaAcumulado: 0,
    garantiaAcumulado: 0,
    totalRodada: 0,
    qtdCartelas: 0,
    acumulado: 0,
    acumuladoRodada: 0,
    maxPontos: 0,
    percAcumulado: 0,
    percPremio: 0,
    percServico: 0,
  });
  useEffect(() => {
    const carregarDados = () => {
      setIsLoading(true);

      apiBasic
        .get(`rodadas/0/resumo`)
        .then((resumoRodada) => {
          setResumo(resumoRodada.data);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
    };

    carregarDados();
  }, []);

  return (
    <>
      <div
        style={{
          fontSize: '12px',
          position: 'absolute',
          width: '380px',
          textAlign: 'center',
          top: '90px',
          color: 'yellow',
          fontWeight: 'bold',
          borderBottom: '1px dotted white',
          borderRadius: '15px 15px 0px 0px',
        }}
      >
        Até o momento
      </div>
      <div
        style={{
          position: 'absolute',
          top: '100px',
          width: '380px',
          padding: '10px 0px',
          color: 'yellow',
          fontWeight: 'bold',
          fontSize: 16,
          borderRadius: '25px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginRight: 10, textAlign: 'left' }}>
          <div>PRÊMIO DA RODADA </div>
          <div style={{ fontSize: '14px' }}>maior pontuador </div>
          <div
            style={{
              fontSize: 30,
              color: 'white',
            }}
          >
            {formataMoeda(resumo.valorPremiacao)}
          </div>
        </div>
        <div
          style={{
            textAlign: 'right',
            borderLeft: '2px solid white',
            paddingLeft: 10,
          }}
        >
          {' '}
          <div>PRÊMIO ACUMULADO</div>
          <div style={{ fontSize: '14px' }}>13+ pontos </div>{' '}
          <div
            style={{
              fontSize: 30,
              color: 'white',
            }}
          >
            {formataMoeda(
              resumo.acumulado +
              resumo.acumuladoRodada +
              resumo.valorGarantiaAcumulado
            )}
          </div>
        </div>
        <div
          style={{
            fontSize: '12px',
            position: 'absolute',
            width: '380px',
            textAlign: 'center',
            top: '100px',
            color: 'yellow',
            fontWeight: 'bold',
            borderBottom: '1px dotted white',
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '200px',
          width: '380px',
          margin: '10px 0px',
          fontWeight: 'bold',
          fontSize: 10,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: '#191919',
          borderRadius: '0px 0px 15px 15px',
        }}
      >
        <div style={{ fontSize: 14, height: 15, color: '#FFF05A' }}>VALOR DO PALPITE</div>
        <div style={{ fontSize: 30, color: '#E1EFF6' }}>
          {formataMoeda(resumo.valorUnitario)}
        </div>
      </div>
    </>
  );
};

export default GridPremios;

import { Switch, Route, BrowserRouter } from 'react-router-dom';
import NavBar from 'components/Navbar';
import Home from 'pages/Home';
import Acesso from 'pages/Acesso';
import Regras from 'pages/Regras';
import Ranking from 'pages/Ranking';
import Rodadas from 'pages/Rodadas';
import ContaCorrente from 'pages/ContaCorrente';
import PixPage from 'pages/Pix';
import EquipesPage from 'pages/Equipes';
import TranferPage from 'pages/Transfer';

const Routes = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/ranking">
          <Ranking />
        </Route>
        <Route path="/regras">
          <Regras />
        </Route>
        <Route path="/rodadas">
          <Rodadas />
        </Route>
        <Route path="/pix">
          <PixPage />
        </Route>
        <Route path="/transfers">
          <TranferPage />
        </Route>
        <Route path="/equipes">
          <EquipesPage />
        </Route>
        <Route path="/acesso">
          <Acesso />
        </Route>

        <Route path="/contaCorrente">
          <ContaCorrente />
        </Route>

        
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatInTimeZone } from 'date-fns-tz';
import './style.css';
import { faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import JogoDTO from 'dtos/JogoDTO';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { apiToken } from 'api';
import { useContext, useState } from 'react';
import BetsService from 'contexts/BetsService';
import { toast } from 'react-toastify';
import CadJogo from 'pages/CadJogo';
import moment from 'moment';
import { AxiosRequestConfig } from 'axios';
import EquipeDTO from 'dtos/EquipeDTO';
import CampeonatoDTO from 'dtos/CampeonatoDTO';
import { mensagemErro } from 'Utils';

type Props = {
  data: JogoDTO;
  equipes: EquipeDTO[];
  campeonatos: CampeonatoDTO[];
  onEdit?: any;
  editable: boolean;
  reload: any;
};

const JogoItem = ({
  equipes,
  campeonatos,
  data,
  onEdit,
  editable,
  reload,
}: Props) => {
  const { user }: any = useContext(BetsService);

  const STATUS = ['EM BREVE', 'BOLA ROLANDO', 'ENCERRADO', 'CANCELADO'];

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const [showModalJogo, setShowModalJogo] = useState(false);

  const deletarItem = async (jogo: JogoDTO) => {
    apiToken
      .delete(`jogos/${jogo.id}`, config)
      .then(() => {
        setShow(false);
        toast.success('Jogo excluída com sucesso !!!');
        reload();
      })
      .catch((e) => {
        setShow(false);
        toast.error(mensagemErro(e));

      });
  };

  const updateJogo = (data: any) => {
    const _jogo = {
      ...data,
      dataEvento: moment(data.dataEvento),
      timeCasa: data.timeCasa.id,
      timeFora: data.timeFora.id,
      campeonato: data.campeonato.id,
      resultado: data.resultado,
    };

    setJogo(_jogo);
    setShowModalJogo(true);
  };

  const [jogo, setJogo] = useState<JogoDTO>();

  const [show, setShow] = useState(false);

  return (
    <>
      <CadJogo
        reload={reload}
        data={jogo}
        equipes={equipes}
        campeonatos={campeonatos}
        show={showModalJogo}
        setShow={setShowModalJogo}
      />

      <Modal show={show} onHide={() => setShow(false)} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', fontSize: 16 }}>
            CONFIRMAÇÃO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 14 }}>
          Confirma exclusão do Jogo{' '}
          <strong> {data?.ordem.toString().padStart(2, '0')}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            style={{ color: 'white' }}
            onClick={() => deletarItem(data)}
          >
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="linha-jogo" key={data.id}>
        <div style={{ width: '1%' }}>
          {data?.ordem.toString().padStart(2, '0')}
        </div>
        <div style={{ width: '20%', textAlign: 'right' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img src={data.timeCasa.logo} alt="" width={20} />
            {data.timeCasa.sigla}{' '}
            <div style={{ fontSize: '16px' }}>{data.placar}</div>{' '}
            {data.timeFora.sigla}
            <img src={data.timeFora.logo} alt="" width={20} />
          </div>
        </div>
        <div style={{ width: '15%' }}>
          {formatInTimeZone(
            new Date(data.dataEvento),
            'America/Fortaleza',
            'dd/MM/yyyy HH:mm'
          )}
        </div>

        <div style={{ width: '22%', textAlign: 'left' }}>
          {data.campeonato.name}
        </div>
        <div style={{ width: '120px', textAlign: 'left' }}>
          {STATUS[data.status]}
        </div>

        {editable && (
          <div className="botoes-acoes">
             {data.rodada.status == 2 && (<FontAwesomeIcon
              style={{ marginTop: 0, paddingRight: '10px' }}
              icon={faEdit}
              className="icon-edit"
              title="Alterar Jogo"
              onClick={() => updateJogo(data)}
            />)}
            {data.status == 0 && (
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginTop: 0, paddingRight: '10px' }}
                className="icon-delete"
                onClick={() => setShow(true)}
                title="Remover Jogo"
              />
            )}
              <a href={data.link} target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faExternalLink}
                  className="icon-link"
                  title="Visitar site do Jogo"
                />
              </a>
            
          </div>
        )}
      </div>
    </>
  );
};
export default JogoItem;

import { Form } from 'antd';
import { apiToken } from 'api';
import { AxiosRequestConfig } from 'axios';
import ButtonIcon from 'components/ButtonIcon';
import Lancamento from 'components/Lancamento';
import Navegador from 'components/Navegador';
import BetsService from 'contexts/BetsService';

import LancamentoDTO from 'dtos/LancamentoDTO';
import PixDTO from 'dtos/PixDTO';
import { useContext, useEffect, useState } from 'react';
import PageConfig from 'types/PageConfig';
import { mensagemErro } from 'Utils';
import './style.css';
import SaqueDTO from 'dtos/SaqueDTO';
import Saque from 'components/Saques';

const TranferPage = () => {
  const { user }: any = useContext(BetsService);
  const [pageConfig, setPageConfig] = useState<PageConfig>();
  const [isReloading, setIsReloading] = useState(false);
  const [page, setPage] = useState<number>(0);
  
  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const { setIsLoading, toast }: any = useContext(BetsService);

  const [lancamentos, setLancamentos] = useState<SaqueDTO[]>([]);

   useEffect(() => {
    setIsLoading(true);

    apiToken
      .get(`contacorrente/saques-pendentes?page=${page}`, config)
      .then((retorno) => {
        setLancamentos(retorno.data.content);

        const p: number[] = [];
        for (let index = 0; index < retorno.data.totalPages; index++) {
          p.push(index);
        }
        setPageConfig({
          totalElements: retorno.data.totalElements,
          totalElementsPage: retorno.data.numberOfElements,
          totalPages: retorno.data.totalPages,
          currentPage: retorno.data.pageable.pageNumber,
          pages: p,
        });

        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));
        setIsLoading(false);
      });
  }, [isReloading, page]);

  const reload = () => {
    setIsReloading(!isReloading);
  };

  return (
    <div>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-container-top">
            <div className="home-left-container-top-left">
              <div className="home-left-container-top-left-top">
                <div className="home-left-container-top-left-top-title">
                  <div className="home-left-container-top-left-top-title-text">
                    <h3>SAQUES</h3>
                    <h6>Gestão de Tranferência de Valores</h6>
                  </div>
                </div>
                <div className="home-left-container-top-right">

                </div>
              </div>
              <div className="home-left-container-top-left-area">
                <div className="container pix-body">
                  <div className="container pix-body">
                    {lancamentos &&
                      lancamentos?.map((l) => {
                        return (
                          <Saque
                            data={l}
                            config={config}
                            reload={reload}
                            carteira={false}
                          />
                        );
                      })}

                    {!lancamentos ||
                      (lancamentos.length === 0 && (
                        <div
                          className="box-navigator"
                          style={{
                            fontSize: 16,
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'red',
                            textAlign: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          <div>
                            Não existem Saque para confirmação no momento.
                          </div>
                        </div>
                      ))}
                  </div>

                  <div style={{ paddingTop: 5 }}>
                    <Navegador
                      pageConfig={pageConfig}
                      setPage={setPage}
                      elemento="Saques"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranferPage;

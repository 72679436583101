import {apiToken} from 'api';
import BetsService from 'contexts/BetsService';
import { useContext } from 'react';
import { fmtDate, mensagemErro } from 'Utils';
import moment from 'moment-timezone';
import { Form, Modal, Row, Col, Input, Select, DatePicker, Button } from 'antd';
import CampeonatoDTO from 'dtos/CampeonatoDTO';
import locale from 'antd/es/date-picker/locale/pt_BR';

import JogoDTO from 'dtos/JogoDTO';
import { AxiosRequestConfig } from 'axios';
import EquipeDTO from 'dtos/EquipeDTO';

type Props = {
  data?: JogoDTO;
  equipes: EquipeDTO[],
  campeonatos: CampeonatoDTO[]
  reload: any;
  show: boolean;
  setShow: any;
  rodada?: number;
};

const CadJogo = ({ equipes, campeonatos, data, reload, show, setShow, rodada }: Props) => {
  moment.tz.setDefault('UTC-3');

  const { user, setIsLoading, toast }: any = useContext(BetsService);
 
  const [form] = Form.useForm();

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

    const config: AxiosRequestConfig = {    
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const initialValues = {
    id: undefined,
    timeCasa: undefined,
    timeFora: undefined,
    campeonato: undefined,
    rodada: undefined,
    data: undefined,
    resultado: undefined,
    link: undefined,
    placar: undefined,
    ordem: 0,
  };

  

  const salvarJogo = async (values: any) => {
    values.dataEvento = fmtDate(values.dataEvento);
    values.rodada = data? data.rodada.id : rodada;

    setIsLoading(true);
    if (values && values.id) {
      apiToken
        .put(`jogos`, values, config)
        .then(() => {
          setShow(false);
          reload();
          toast.success('Jogo atualizado com sucesso');
        })
        .catch((e) => {
          setShow(false);
          reload();
          toast.error(
            mensagemErro(e)
          );
        });
    } else {
      apiToken
        .post(`jogos`, values, config)
        .then(() => {
          setShow(false);
          reload();
          toast.success('Jogo cadastrado com sucesso');
        })
        .catch((e) => {
          setShow(false);
          reload();
          toast.error(
            mensagemErro(e)
          );
        });
    }
  };

  return (
    <Modal
      title="CADASTRO DE JOGO"
      centered
      open={show}
      onCancel={() => setShow(false)}
      footer={null}
      width={770}
    >
      <Form
        form={form}
        name="basic"
        initialValues={data}
        layout="vertical"
        onFinish={salvarJogo}
      >
        <Row
          style={{
            background: 'white',
            minWidth: '100%',
          }}
        >
          <Col
            span={12}
            style={{
              display: 'flex',
              marginRight: 10,
            }}
          >
            <Form.Item
              label="ID"
              name="id"
              rules={[]}
              style={{
                marginRight: 20,
              }}
            >
              <Input name="id" disabled style={{ width: 85 }} />
            </Form.Item>
            <Form.Item
              label="DATA"
              name="dataEvento"
              rules={[{ required: true, message: 'Informe a data' }]}
              style={{
                marginRight: 20,
              }}
            >
              <DatePicker
                locale={locale}
                style={{ width: 170 }}
                showTime={{ format: 'HH:mm' }}
                format="DD/MM/YYYY HH:mm"
              />
            </Form.Item>

            <Form.Item
              label="TIME CASA"
              name="timeCasa"
              rules={[{ required: true, message: 'Selecione uma opção' }]}
              style={{
                marginRight: 20,
              }}
            >
              <Select
                style={{ width: 200, fontSize: '12px' }}
                options={equipes}
              ></Select>
            </Form.Item>

            <Form.Item
              label="TIME FORA"
              name="timeFora"
              rules={[{ required: true, message: 'Selecione uma opção' }]}
              style={{
                marginRight: 20,
              }}
            >
              <Select
                style={{ width: 200, fontSize: '12px' }}
                options={equipes}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            background: 'white',
            minWidth: '100%',
          }}
        >
          <Col
            span={16}
            style={{
              display: 'flex',
              marginRight: 10,
            }}
          >
            <Form.Item
              label="LINK"
              name="link"
              rules={[]}
              style={{
                marginRight: 10,
                width: '70%',
              }}
            >
              <Input name="link" />
            </Form.Item>
            <Form.Item
              label="LOCAL"
              name="local"
              rules={[]}
              style={{
                marginRight: 10,
              }}
            >
              <Input name="local" />
            </Form.Item>
          </Col>
          
          <Col
            span={6}
            style={{
              display: 'flex',
              marginRight: 10,
              width: '100%',
            }}
          >
            <Form.Item
              label="CAMPEONATO"
              name="campeonato"
              rules={[{ required: true, message: 'Selecione uma opção' }]}
              style={{
                marginRight: 20,
              }}
            >
              <Select
                style={{ width: 220, fontSize: '12px' }}
                options={campeonatos}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              display: 'flex',
              marginRight: 10,
            }}
          >
            <Form.Item
              label="RESULTADO"
              name="resultado"
              rules={[]}
              style={{
                marginRight: 20,
              }}
            >
              <Select
                id="resultado"
                style={{ width: 110, fontSize: '12px' }}
                options={[
                  { value: 0, label: 'EMPATE' },
                  { value: 1, label: 'CASA' },
                  { value: 2, label: 'FORA' },
                  { value: 3, label: '-' },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item
              label="PLACAR"
              name="placar"
              rules={[]}
              style={{
                marginRight: 20,
                width: 80

              }}
            >
              <Input name="placar" />
            </Form.Item>
            <Form.Item
              label="ORDEM"
              name="ordem"
              rules={[]}
              style={{
                marginRight: 20,
                width: 80
              }}
            >
              <Input name="ordem" />
            </Form.Item>
            <Form.Item
              label="SITUACAO"
              name="status"
              rules={[]}
              style={{
                marginRight: 20,
              }}
            >
              <Select
                id="status"
                style={{ width: 170, fontSize: '12px' }}
                options={[
                  { value: 0, label: 'EM BREVE' },
                  { value: 1, label: 'BOLA ROLANDO' },
                  { value: 2, label: 'ENCERRADO' },
                  { value: 3, label: 'CANCELADO' },

                ]}
              ></Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginTop: 30,
                  width: 150,
                  borderRadius: 0,
                  marginRight: -35,
                }}
              >
                SALVAR
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CadJogo;

import { Form } from 'antd';
import { apiToken } from 'api';
import { AxiosRequestConfig } from 'axios';
import ButtonIcon from 'components/ButtonIcon';
import Lancamento from 'components/Lancamento';
import Navegador from 'components/Navegador';
import BetsService from 'contexts/BetsService';

import LancamentoDTO from 'dtos/LancamentoDTO';
import PixDTO from 'dtos/PixDTO';
import { useContext, useEffect, useState } from 'react';
import PageConfig from 'types/PageConfig';
import { mensagemErro } from 'Utils';
import './style.css';
import EquipeDTO from 'dtos/EquipeDTO';
import EquipeItem from 'components/EquipeItem';
import CadEquipe from 'pages/CadEquipe';

const EquipesPage = () => {
  const { user }: any = useContext(BetsService);
  const [pageConfig, setPageConfig] = useState<PageConfig>();
  const [isReloading, setIsReloading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [isNew, setIsNew] = useState(false);

  const tailLayout = {
    wrapperCol: { span: 24 },
  };
  const [formData] = Form.useForm();

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };
  
  const { setIsLoading, toast }: any = useContext(BetsService);

  const [equipes, setEquipes] = useState<EquipeDTO[]>([]);
  const [equipe, setEquipe] = useState<EquipeDTO>();

  
  useEffect(() => {
    setIsLoading(true);

    apiToken
      .get(`equipes/paged?page=${page}`, config)
      .then((teams) => {
        setEquipes(teams.data.content);

        const p: number[] = [];
        for (let index = 0; index < teams.data.totalPages; index++) {
          p.push(index);
        }
        setPageConfig({
          totalElements: teams.data.totalElements,
          totalElementsPage: teams.data.numberOfElements,
          totalPages: teams.data.totalPages,
          currentPage: teams.data.pageable.pageNumber,
          pages: p,
        });

        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));
        setIsLoading(false);
      });
  }, [isReloading, page]);

  const reload = () => {
    setIsReloading(!isReloading);
  };

  const initialValues= {
    id: '',
    name: '',
    sigla: '',
    logo: '',
    uf: '',
    divisao: 0,
    svg:''
  };


  const deletarItem = async (team: EquipeDTO) => {
    apiToken
      .delete(`equipes/${team.id}`, config)
      .then(() => {
        toast.success('Equipe excluída com sucesso !!!');
        setIsNew(false);
        reload();
      })
      .catch((e) => {
        toast.error(mensagemErro(e));

      });
  };

  const updateItem = (_item: any) => {
    setEquipe(_item);
    setIsNew(true);
  };

  
  const cancelarEdicao = async () => {
    setIsNew(false);
    reload();
  };

  
  const editEquipe = (t: EquipeDTO) => {
    setEquipe(t);
    setIsNew(true);
  };

  const novaEquipe = () => {
    setEquipe(initialValues);
    
    setIsNew(true);
  };


  return (
    <div>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-container-top">
            <div className="home-left-container-top-left">
              <div className="home-left-container-top-left-top">
                <div className="home-left-container-top-left-top-title">
                  <div className="home-left-container-top-left-top-title-text">
                    <h3>EQUIPES</h3>
                    <h6>{isNew ? 'Cadastro de Equipe':'Listagem de Equipes'}</h6>
                  </div>
                </div>
                <div className="home-left-container-top-right">        
                {!isNew && (
                      <ButtonIcon
                        text="ADICIONAR"
                        action={() => novaEquipe()}
                        position="right"
                      />
                    )}
                    

                    {isNew && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        
                        <ButtonIcon
                          text="CANCELAR"
                          action={() => cancelarEdicao()}
                          color="bg-danger btn-secondary"
                        />
                        <div style={{ width: '160px' }} />
                      </div>
                    )}          
                </div>
              </div>
              <div className="home-left-container-top-left-area">
              <div className="container pix-body">
                <div className="container pix-body">
                    {!isNew && equipes &&
                      equipes?.map((team) => {
                        return (
                          <EquipeItem                           
                          data={team}
                          reload={()=> {}
                          }
                          onEdit={() => editEquipe(team)}
                          onDelete={() => {
                            deletarItem(team)
                            setIsNew(true);
                          }}
                          />
                        );
                      })}                    
                  </div>
                  {!isNew && (

                  <div style={{ paddingTop: 5 }}>
                    <Navegador
                      pageConfig={pageConfig}
                      setPage={setPage}
                      elemento="Equipes"
                    />
                  </div>)}

                  {isNew && (
                    <div className="container">
                      <CadEquipe  reload={reload}

                        setIsNew={setIsNew}
                        equipe={equipe}
                      />
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipesPage;

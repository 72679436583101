import './assets/styles/custom.scss';
import './App.css';
import Routes from 'Router';
import { useEffect, useState } from 'react';
import BetsService from 'contexts/BetsService';
import UserDTO from 'dtos/UserDTO';
import RodadaDTO from 'dtos/RodadaDTO';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import ApostaDTO from 'dtos/ApostaDTO';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import { apiBasic } from 'api';
import { mensagemErro } from 'Utils';
import JogoDTO from 'dtos/JogoDTO';

const App = () => {
  const [user, setUser] = useState<UserDTO>();
  const [rodada, setRodada] = useState<RodadaDTO>();
  const [rodadas, setRodadas] = useState<RodadaDTO[]>();
  const [jogos, setJogos] = useState<JogoDTO[]>();
  const [apostas, setApostas] = useState<ApostaDTO[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [saldo, setSaldo] = useState(0.0);

  useEffect(() => {
    const carregarDados = async () => {
      setIsLoading(true);

      apiBasic
        .get(`rodadas/ativa`)
        .then((r) => {
          setRodada(r.data);
          apiBasic.get(`rodadas/${r.data.id}/jogos`).then((j) => {
            setJogos(j.data);
            setIsLoading(false);
          });

          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
    };
    carregarDados();
  }, []);

  return (
    <BetsService.Provider
      value={{
        user,
        setUser,
        rodada,
        setRodada,
        setIsLoading,
        toast,
        rodadas,
        setRodadas,
        apostas,
        setApostas,
        saldo,
        setSaldo,
        jogos,
        setJogos
      }}
    >
      <ConfigProvider locale={ptBR}>
        <ToastContainer />
        {isLoading && (
          <>
            <div
              style={{
                width: '100%',
                height: '100%',
                top: '0px',
                position: 'fixed',
                zIndex: '2',
                left: '0px',
                background: '#000',
                opacity: '0.1',
              }}
            ></div>
            <ReactLoading
              type={'spinningBubbles'}
              color={'gray'}
              height={'15%'}
              width={'15%'}
              className="loading"
            />
          </>
        )}
        <div className="App">
          <Routes />
        </div>
      </ConfigProvider>
    </BetsService.Provider>
  );
};

export default App;

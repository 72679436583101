import { useContext } from 'react';
import './style.css';
import 'bootstrap/js/src/collapse.js';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCreditCard,
  faFutbol,
  faGears,
  faPeopleGroup,
  faRankingStar,
  faReceipt,
  faRotate,
  faScaleBalanced,
  faSignIn,
  faSliders,
  faMoneyBillTransfer
} from '@fortawesome/free-solid-svg-icons';
import BetsService from 'contexts/BetsService';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import { useHistory } from 'react-router-dom';
import { GoogleLogout } from 'components/google';
import { carregaSaldoUsuario, formataMoeda } from 'Utils';
import pix from 'assets/images/pix.png';
import { toast } from 'react-toastify';

const NavBar = () => {
  const versao = 'v1.0.37';
  const { user, setUser, saldo, setIsLoading, setSaldo }: any =
    useContext(BetsService);
  const history = useHistory();

  if (!user) {
    history.push('/acesso');
  }

  const atualizaSaldo = () => {
    carregaSaldoUsuario(user, setIsLoading, setSaldo, toast);
  };

  const logout = () => {
    setUser(undefined);
    history.push('/acesso');
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          fontSize: 12,
          top: '94vh',
          left: '82vw',
          color: '#FFF',
          textAlign: 'right'
        }}
      >
        <p style={{ fontWeight: 'bold', fontSize: 8, marginBottom: 0 }}>
          DEVELOPED BY
        </p>{' '}
        Evandro Custodio &#169; Todos os direitos reservados - 2023
      </div>
      <nav className="navbar navbar-expand-md main-nav navbar-light">
        <div className="container-fluid">
          <Link to="/" className="nav-logo-text ">
            <div className="logo">
              <FontAwesomeIcon icon={faFutbol} className="icone" />
              <h4>
                eCamisa12
                <div style={{ fontSize: 11, marginTop: -5, marginLeft: 70 }}>
                  {versao}
                </div>
              </h4>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#dscatalog-navbar"
            aria-controls="dscatalog-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="dscatalog-navbar">
            <ul className="navbar-nav offset-md-2 main-menu">
              {user && (
                <li>
                  <NavLink to="/" activeClassName="active">
                    <div className="iconeNav">
                      <FontAwesomeIcon
                        style={{ fontSize: 30, paddingBottom: 2 }}
                        icon={faReceipt}
                      />
                      <br />
                      PALPITES
                    </div>
                  </NavLink>
                </li>
              )}
              {user && (
                <li>
                  <NavLink to="/ranking">
                    <div className="iconeNav">
                      <FontAwesomeIcon
                        style={{ fontSize: 30, paddingBottom: 2 }}
                        icon={faRankingStar}
                      />
                      <br />
                      RANKING
                    </div>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink to="/regras">
                  <div className="iconeNav">
                    <FontAwesomeIcon
                      style={{ fontSize: 30, paddingBottom: 2 }}
                      icon={faScaleBalanced}
                    />
                    <br />
                    REGRAS
                  </div>
                </NavLink>
              </li>

              
              {user && user.administrator === true && (<>
                <li>                  
                  <NavLink to="/rodadas">
                    <div className="iconeNav">
                      <FontAwesomeIcon
                        style={{ fontSize: 30, paddingBottom: 2 }}
                        icon={faGears}
                      />
                      <br />
                      RODADAS
                    </div>
                  </NavLink>
                </li>
                <li>
                <NavLink to="/equipes">
                  <div className="iconeNav">
                    <FontAwesomeIcon
                      style={{ fontSize: 30, paddingBottom: 2 }}
                      icon={faPeopleGroup}
                    />
                    <br />
                    EQUIPES
                  </div>
                </NavLink>
              </li>
                <li>
                  <NavLink to="/pix">
                    <div className="iconeNav">
                      <FontAwesomeIcon
                        style={{ fontSize: 30, paddingBottom: 2 }}
                        icon={faCreditCard}
                      />
                      <br />
                      PIX
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/transfers">
                    <div className="iconeNav">
                      <FontAwesomeIcon
                        style={{ fontSize: 30, paddingBottom: 2 }}
                        icon={faMoneyBillTransfer}
                      />
                      <br />
                      SAQUES
                    </div>
                  </NavLink>
                </li>

                
                </>
              )}

              {!user && (
                <li>
                  <NavLink to="/acesso">
                    <div className="iconeNav">
                      <FontAwesomeIcon
                        style={{ fontSize: 30, paddingBottom: 2 }}
                        icon={faSignIn}
                      />
                      <br />
                      ACESSO
                    </div>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>

          {user && (
            <div className='area-usuario'>
              <div
                style={{
                  fontSize: 10,
                  color: '#FFF',
                  textAlign: 'start',
                  paddingRight: 10,
                }}
              >
                <div style={{ fontWeight: 'bold' }}>ID</div>
                <div className='id-usuario'>
                  {user.id.toString().substr(-6)}
                </div>
              </div>
              <div style={{ fontSize: 10, color: '#FFF', textAlign: 'end' }}>
                <div style={{ fontWeight: 'bold' }}>PLAYER</div>
                <div className='id-usuario'>{user.name}</div>
              </div>
              <div
                style={{
                  fontSize: 10,
                  color: '#FFF',
                  paddingRight: 10,
                  width: '130px',
                }}
              >
                <div style={{ fontWeight: 'bold', textAlign: 'end' }}>
                  SALDO
                </div>

                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'end',
                    color: 'lightgreen',
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      marginRight: 10,
                      fontSize: 15,
                      color: 'lightgreen',
                      cursor: 'pointer',
                    }}
                    title="Atualizar Saldo"
                    onClick={() => atualizaSaldo()}
                    icon={faRotate}
                  />
                  {formataMoeda(saldo)}
                </div>
              </div>

              <div style={{ color: 'white' }}>
                <img
                  src={pix}
                  height="35px"
                  style={{ color: 'white', cursor: 'pointer' }}
                  title="Clique aqui para depositar valores via PIX."
                  onClick={() => history.push('/contacorrente')}
                />
              </div>

              {user.image && (
                <div className="usuarioimage">
                  <img src={user.image} />
                </div>
              )}
              <div className="div-botao-login">
                <div className="botaoLogin" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon 
                    className="botao-close"
                    onClick={() => logout()}
                    icon={faCircleXmark}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default NavBar;

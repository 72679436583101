import { Button, Col, Form, Input, Row, Tabs } from 'antd';
import { apiToken } from 'api';
import { AxiosRequestConfig } from 'axios';
import ButtonIcon from 'components/ButtonIcon';
import Lancamento from 'components/Lancamento';
import Navegador from 'components/Navegador';
import BetsService from 'contexts/BetsService';
import { BankOutlined, DollarOutlined  } from '@ant-design/icons';
import format from 'date-fns/format';
import LancamentoDTO from 'dtos/LancamentoDTO';
import PixDTO from 'dtos/PixDTO';
import { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import PageConfig from 'types/PageConfig';
import { fmtValor, formataMoeda, mensagemErro } from 'Utils';
import './style.css';
import copy from 'copy-to-clipboard';
import Saque from 'components/Saques';
import SaqueDTO from 'dtos/SaqueDTO';

const ContaCorrente = () => {
  const { user }: any = useContext(BetsService);
  const [confPag, setConfPag] = useState(false);
  const [pageConfig, setPageConfig] = useState<PageConfig>();
  const [isReloading, setIsReloading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [opcao,setOpcao] = useState("1");

  const tailLayout = {
    wrapperCol: { span: 24 },
  };
  const [formData] = Form.useForm();
  const [formDataSaque] = Form.useForm();

  const initialValues = {
    cpf: '',
    nome: '',
    descricao: '',
    valor: '5,0',
    identificacao: user.id,
  };

  
  const initialValuesSaques = {
    id:  "",
    valor:  0,
    chave: "",
    txId:  "",
    status:  0,
    player: user.id,
  };

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const [pix, setPix] = useState<PixDTO>();
  const { setIsLoading, toast }: any = useContext(BetsService);

  const [lancamentos, setLancamentos] = useState<LancamentoDTO[]>([]);
  const [saques, setSaques] = useState<SaqueDTO[]>([]);

  const gerarQRCode = async (values: any) => {
    setIsLoading(true);

    const data = {
      cpf: values.cpf,
      nome: values.nome,
      valor: fmtValor(values.valor),
      descricao: values.descricao,
      identificacao: user.id.toString(),
    };

    apiToken
      .post(`contacorrente/depositos`, data, config)
      .then((result) => {
        setPix(result.data);
        setIsLoading(false);
        setIsReloading(!isReloading);
      })
      .catch((e) => {
        setPix(undefined);

        if (e.response.data.erros) {
          e.response.data.erros.map((a: any) => {
            toast.error(a.message);
          });
        } else {
          toast.error(mensagemErro(e));
        }
        setIsLoading(false);
      });
  };


  const solicitarSaque = async (values: any) => {
    setIsLoading(true);
    
    const dataSaque = {
      valor:  fmtValor(values.valor),
      name: values.name,
      chave:  values.chave,           
      detalhes: values.detalhes,
      criacao:  format(new Date(),'yyyy-MM-dd HH:mm:ss')      
    };

    apiToken
      .post(`contacorrente/saques`, dataSaque, config)
      .then((result) => {
        toast.success('Saque solicitado com sucesso');
        setIsLoading(false);
        setIsReloading(!isReloading);
        formDataSaque.resetFields();
    
      })
      .catch((e) => {
        if (e.response.data.erros) {
          e.response.data.erros.map((a: any) => {
            toast.error(a.message);
          });
        } else {
          toast.error(mensagemErro(e));
        }
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);

    if (opcao =='1') {
        apiToken
          .get(`contacorrente/depositos`, config)
          .then((pixes) => {
            setLancamentos(pixes.data.content);

            const p: number[] = [];
            for (let index = 0; index < pixes.data.totalPages; index++) {
              p.push(index);
            }
            setPageConfig({
              totalElements: pixes.data.totalElements,
              totalElementsPage: pixes.data.numberOfElements,
              totalPages: pixes.data.totalPages,
              currentPage: pixes.data.pageable.pageNumber,
              pages: p,
            });

            setIsLoading(false);
          })
          .catch((e) => {
            if (e.response.data.erros) {
              e.response.data.erros.map((a: any) => {
                toast.error(a.message);
              });
            } else {
              toast.error(mensagemErro(e));
            }
            setIsLoading(false);
          });
        } else {
          apiToken
          .get(`contacorrente/saques`, config)
          .then((registros) => {
            setSaques(registros.data.content);

            const p: number[] = [];
            for (let index = 0; index < registros.data.totalPages; index++) {
              p.push(index);
            }
            setPageConfig({
              totalElements: registros.data.totalElements,
              totalElementsPage: registros.data.numberOfElements,
              totalPages: registros.data.totalPages,
              currentPage: registros.data.pageable.pageNumber,
              pages: p,
            });

            setIsLoading(false);
          })
          .catch((e) => {
            if (e.response.data.erros) {
              e.response.data.erros.map((a: any) => {
                toast.error(a.message);
              });
            } else {
              toast.error(mensagemErro(e));
            }
            setIsLoading(false);
          });
        }
  }, [isReloading]);

  const limpaTudo = () => {
    setPix(undefined);
    formData.resetFields();
    formDataSaque.resetFields();
  };

  const confirmarPagamento = () => {
    limpaTudo();
    setConfPag(true);
  };

  const reload = () => {
    setIsReloading(!isReloading);
  };

  const copyToClipboard = () => {
    if (pix) {
      copy(pix.qrcode);
      toast.success('Código Pix Copiado com sucesso !!!');
    }
  };

  const changeOpcao = (opc:any) => {
    setOpcao(opc);
    reload();
  }

  return (
    <div>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-container-top">
            <div className="home-left-container-top-left">
              <div className="home-left-container-top-left-top">
                <div className="home-left-container-top-left-top-title">
                  <div className="home-left-container-top-left-top-title-text">
                    <h3>MOVIMENTAÇÃO FINANCEIRA</h3>
                  </div>
                </div>
              </div>
              <div className="home-left-container-top-left-area">
                {opcao=='1' && <div className="container regras-body">
                  {lancamentos &&
                    lancamentos?.map((l) => {
                      return (
                        <Lancamento
                          data={l}
                          config={config}
                          reload={reload}
                          carteira={true}
                        />
                      );
                    })}

                  {!lancamentos ||
                    (lancamentos.length === 0 && (
                      <div
                        className="box-navigator"
                        style={{
                          fontSize: 16,
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'red',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <div>
                          Não existem movimentações na sua conta até o momento.
                        </div>
                      </div>
                    ))}

                  {confPag && (
                    <div
                      className="box-navigator"
                      style={{
                        fontSize: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'green',
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      <div>
                        Estamos processando o seu pagamento. Em breve será
                        creditado ao seu saldo.
                      </div>
                    </div>
                  )}
                </div>}

                {opcao=='2' && <div className="container regras-body">
                  {saques &&
                    saques?.map((l) => {
                      return (
                        <Saque
                          data={l}
                          config={config}
                          reload={reload}
                          carteira={true}
                        />
                      );
                    })}

                  {!saques ||
                    (saques.length === 0 && (
                      <div
                        className="box-navigator"
                        style={{
                          fontSize: 16,
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'red',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <div>
                          Não existem saques na sua conta até o momento.
                        </div>
                      </div>
                    ))}
                  
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="home-right-container">
          <Tabs style={{marginLeft: 10}}
            defaultActiveKey="1"
            onChange ={ changeOpcao }
            items={[DollarOutlined, BankOutlined].map((Icon, i) => {
              const id = String(i + 1);
              
              return {
                label: (
                  <div className="home-right-container-top">            
                  <Icon />
                  {`${id == '1' ? 'DEPÓSITO' : 'SAQUES'}`}</div>
                ),
                key: id,
                children: id == '1' ? (
                  <div className="regras-body-left">
                    {!pix && (
                      <Form
                        form={formData}
                        name="basic"
                        initialValues={initialValues}
                        layout="vertical"
                        onFinish={gerarQRCode}
                      >
                        <Row>
                          <Col>
                            <Form.Item
                              label="CPF (sem pontos e traço)"
                              name="cpf"
                              rules={[
                                {
                                  pattern: /^(?:\d*)$/,
                                  message: 'Somente números.',
                                },
                                {
                                  required: true,
                                  message: 'Informe o seu CPF',
                                },
                              ]}
                            >
                              <Input
                                name="cpf"
                                maxLength={11}
                                style={{ width: '250px', fontSize: 16 }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            style={{
                              display: 'flex',
                            }}
                          >
                            <Form.Item
                              label="NOME COMPLETO DO TITULAR"
                              name="nome"
                              rules={[
                                {
                                  required: true,
                                  message: 'Informe o nome completo',
                                },
                                { min: 10, message: 'Informe o nome completo' },
                              ]}
                            >
                              <Input
                                style={{ width: '250px', fontSize: 12 }}
                                name="nome"
                                maxLength={60}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Item
                              label="VALOR DO PIX"
                              name="valor"
                              rules={[
                                { required: true, message: 'Informe o valor' },
                              ]}
                            >
                              <NumericFormat
                                style={{
                                  textAlign: 'right',
                                  width: '250px',
                                  fontSize: 16,
                                }}
                                decimalScale={2}
                                name="valor"
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="primary"
                            style={{
                              width: 250,
                              marginTop: '10px',
                              borderRadius: 0,
                              fontWeight: 'bold',
                            }}
                            htmlType="submit"
                          >
                            GERAR QRCODE
                          </Button>

                          <Button
                            type="primary"
                            style={{
                              width: 250,
                              marginTop: '10px',
                              borderRadius: 0,
                              fontWeight: 'bold',
                            }}
                            onClick={() => limpaTudo()}
                          >
                            LIMPAR{' '}
                          </Button>
                        </Form.Item>
                      </Form>
                    )}
                    {pix && (
                      <>
                        <img src={pix.qrcodeImage} />
                        <div style={{ fontSize: 8 }}>ID DA TRANSAÇÃO</div>
                        <div style={{ fontSize: 12 }}>{pix?.txId}</div>
                        <div style={{ fontSize: 20, marginTop: 10 }}>
                          <strong>
                            Valor: {formataMoeda(parseFloat(pix.valor))}
                          </strong>
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            marginTop: 10,
                            marginBottom: 5,
                          }}
                        >
                          <br />
                          <b>
                            Efetue a leitura do QRcode para efetivar
                            transferência.
                          </b>
                        </div>
                        <div>
                          <ButtonIcon
                            action={copyToClipboard}
                            text="COPIAR PIX"
                          />
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'space-around',
                          }}
                        >
                          <ButtonIcon
                            submit={false}
                            text="CONFIMAR PAGAMENTO"
                            action={() => confirmarPagamento()}
                          />
                          <ButtonIcon
                            submit={false}
                            text="NOVO PIX"
                            action={() => limpaTudo()}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ):(
                  <div className="regras-body-left">

                    <Form
                      form={formDataSaque}
                      name="basic"
                      initialValues={initialValuesSaques}
                      layout="vertical"
                      onFinish={solicitarSaque}
                    >
                      <Row>
                        <Col>
                          <Form.Item
                            label="Chave PIX"
                            name="chave"
                            rules={[
                              {
                                required: true,
                                message: 'Informe a chave PIX',
                              },
                            ]}
                          >
                            <Input
                              name="chave"
                              maxLength={64}
                              style={{ width: '250px', fontSize: 14 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>            
                      <Row>
                        <Col>
                          <Form.Item
                            label="NOME DO BANCO"
                            name="detalhes"
                            rules={[
                              {
                                required: true,
                                message: 'Informe o Nome do BANCO da Conta Corrente',
                              },
                            ]}
                          >
                            <Input
                              name="name"
                              maxLength={100}
                              style={{ width: '250px', fontSize: 14 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>       
                      <Row>
                        <Col>
                          <Form.Item
                            label="NOME DO TITULAR"
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: 'Informe o Nome do TITULAR da Conta Corrente',
                              },
                            ]}
                          >
                            <Input
                              name="name"
                              maxLength={100}
                              style={{ width: '250px', fontSize: 14 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row> 
                      <Row>
                        <Col>
                          <Form.Item
                            label="VALOR DO SAQUE"
                            name="valor"
                            rules={[
                              { required: true, message: 'Informe o valor' },
                            ]}
                          >
                            <NumericFormat
                              style={{
                                textAlign: 'right',
                                width: '250px',
                                fontSize: 16,
                              }}
                              decimalScale={2}
                              name="valor"
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              thousandsGroupStyle="thousand"
                              thousandSeparator="."
                            />
                          </Form.Item>
                        </Col>
                      </Row>                      
                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{
                            width: 250,
                            marginTop: '10px',
                            borderRadius: 0,
                            fontWeight: 'bold',
                          }}
                          htmlType="submit"
                        >
                          SOLICITAR SAQUE
                        </Button>

                        <Button
                          type="primary"
                          style={{
                            width: 250,
                            marginTop: '10px',
                            borderRadius: 0,
                            fontWeight: 'bold',
                          }}
                          onClick={() => limpaTudo()}
                        >
                          LIMPAR{' '}
                        </Button>
                      </Form.Item>
                    </Form>
                  
                  
                </div>),
              };
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default ContaCorrente;

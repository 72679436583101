import {
  faBolt,
  faCancel,
  faCheckCircle,
  faClock,
  faEdit,
  faExternalLink,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BetsService from 'contexts/BetsService';
import { useContext, useState } from 'react';

import './style.css';
import EquipeDTO from 'dtos/EquipeDTO';

type Props = {
  data: EquipeDTO;
  onEdit: any
  onDelete: any
  reload: any;
};

const EquipeItem = ({ data, onDelete, onEdit }: Props) => {

  return (
    <>
      <div className="linha">
        <div style={{ width: '50px' }}>
          <div className="label">ID</div> {data.id.toString().padStart(4, '0')}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 10 }}>
            <img alt="" src={data.logo} width={35} height={35} />
          </div>
          <div style={{ width: '250px', textAlign: 'left', fontSize: 12 }}>
            <div className="label">EQUIPE</div>
            {data.name}
          </div>
        </div>
        <div style={{ width: '160px', textAlign: 'left', fontSize: 12 }}>
          <div className="label">SIGLA</div>
          {data.sigla}
        </div>

        <div style={{ width: '160px', textAlign: 'left', fontSize: 12 }}>
          <div className="label">ORIGEM</div>
          {data.uf}
        </div>
        <div style={{ width: '160px', textAlign: 'left', fontSize: 12 }}>
          <div className="label"> DIVISÃO</div>
          {data.divisao}
        </div>
        
          <div className="botoes-acoes">
             <FontAwesomeIcon
              style={{ marginTop: 10, paddingRight: '20px' }}
              icon={faEdit}
              className="icon-edit"
              title="Alterar Equipe"
              onClick={() => onEdit(data)}
            />
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginTop: 10, paddingRight: '0px' }}
                className="icon-delete"
                onClick={() => onDelete(data)}
                title="Remover Equipe"
              />
          
          </div>
        
      </div>
    </>
  );
};

export default EquipeItem;

import { useState, useEffect, useContext } from 'react';
import './style.css';
import { apiToken } from 'api';
import format from 'date-fns/format';
import sha256 from 'crypto-js/sha256';
import ButtonIcon from 'components/ButtonIcon';
import Cartela from 'components/Cartela';
import ApostaDTO from 'dtos/ApostaDTO';
import JogoDTO from 'dtos/JogoDTO';
import { formatInTimeZone } from 'date-fns-tz';

import PageConfig from 'types/PageConfig';
import ResumoRodada from 'components/ResumoRodada';
import ResumoRodadaDTO from 'types/ResumoRodadaDTO';
import BetsService from 'contexts/BetsService';
import { useHistory } from 'react-router-dom';
import SelectRodada from 'components/SelectRodada';
import { STATUS } from 'pages/Rodadas';
import Navegador from 'components/Navegador';
import { carregaSaldoUsuario, mensagemErro } from 'Utils';
import { AxiosRequestConfig } from 'axios';
import Palpites from 'components/Palpites';
import JogosRodada from 'components/JogosRodada';

const Home = () => {
  const { user, rodada, setSaldo }: any = useContext(BetsService);
  const { setIsLoading, toast, setRodada }: any = useContext(BetsService);

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const history = useHistory();

  if (!user) {
    history.push('/acesso');
  }

  const [apostador] = useState(user?.id);
  const [page, setPage] = useState<number>(0);
  const [pageConfig, setPageConfig] = useState<PageConfig>();
  const [exibeNavegacao, setExibeNavegacao] = useState(false);
  const [cartelaGratis, setCartelaGratis] = useState(false);
  

  const [resumo, setResumo] = useState<ResumoRodadaDTO>({
    totalArrecadado: 0,
    valorUnitario: 0,
    totalServico: 0,
    valorGarantia: 0,
    valorPremiacao: 0,
    valorGarantiaAcumulado: 0,
    garantiaAcumulado: 0,
    totalRodada: 0,
    qtdCartelas: 0,
    acumulado: 0,
    acumuladoRodada: 0,
    maxPontos: 0,
    percAcumulado: 0,
    percPremio: 0,
    percServico: 0,
  });
  const [isReloading, setIsReloading] = useState(false);

  const [aposta, setAposta] = useState<ApostaDTO>();
  const [palpites, setPalpites] = useState([
    3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3
  ]);

  const [isNew, setIsNew] = useState(false);
  const [apostas, setApostas] = useState<ApostaDTO[]>();

  const [jogos, setJogos] = useState<JogoDTO[]>();

  const novaAposta = () => {

    setPalpites([3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]);
    setAposta(undefined);
    setIsNew(true);
  };

  useEffect(() => {
    if (user && rodada) {
      apiToken
        .get(`rodadas/${rodada.id}`, config)
        .then((r) => {
          setRodada(r.data);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const carregarDados = async () => {
      setIsLoading(true);

      carregaSaldoUsuario(user, setIsLoading, setSaldo, toast);

      apiToken
        .get(
          `users/${parseInt(apostador)}/rodada/${
            rodada?.id
          }/apostas?page=${page}`,
          config
        )
        .then((a) => {
          setApostas(a.data.content);
          setExibeNavegacao(a.data.content.length > 0);
          const tamanho = a.data.content.filter((c: ApostaDTO) => c.bonus == false && c.idCartelaBonus == null ).length;
          const isGratis = tamanho >= rodada.qtdMinCartelas && rodada.flgBonusCartelas;
          setCartelaGratis(isGratis)          
          const p = [];
          for (let index = 0; index < a.data.totalPages; index++) {
            p.push(index);
          }
          setPageConfig({
            totalElements: a.data.totalElements,
            totalElementsPage: a.data.numberOfElements,
            totalPages: a.data.totalPages,
            currentPage: a.data.pageable.pageNumber,
            pages: p,
          });
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });

      apiToken
        .get(`rodadas/${rodada?.id}/jogos`, config)
        .then((j) => {
          setJogos(j.data);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });

      apiToken
        .get(`rodadas/${rodada?.id}/resumo`, config)
        .then((resumoRodada) => {
          setResumo(resumoRodada.data);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });

    };

    if (rodada) {
      carregarDados();
    } else {
      setIsLoading(false);
    }
  }, [isReloading, apostador, page, rodada]);

  const configura = async (posic: number, opt: number) => {
    palpites[posic] = opt;
    setPalpites(palpites);

    reload();
  };

  const valorPalpite = (posic: number, j?: any[]) => {
    if (j) {
      switch (palpites[posic]) {
        case 0:
          return 'EMPATE';
        case 1:
          return j[posic].timeCasa.name;
        case 2:
          return j[posic].timeFora.name;
      }
    }
    return '';
  };

  const cancelarEdicao = async () => {
    setIsNew(false);
    reload();
  };

  const salvarAposta = async () => {
    setIsLoading(true);
    const a = apostador.toString().padStart(7, '0');
    const r = rodada?.id.toString().padStart(7, '0');
    const d = formatInTimeZone(
      new Date(),
      'America/Fortaleza',
      'dd/MM/yyyy HH:mm:ss'
    );
    if (palpites.toString().includes('3')) {
      toast.error('Palpite Incompleto: Ainda existem jogos sem palpite.');
      setIsLoading(false);
      return;
    }
    const p = palpites.toString().replaceAll(',', '');
    const hash = sha256(d + r + a + p).toString();
    if (aposta && aposta.id) {
      apiToken
        .put(
          `apostas`,
          {
            id: aposta?.id,
            rodada: rodada?.id,
            apostador: parseInt(apostador),
            hashInicial: aposta?.hashInicial ? aposta.hashInicial : hash,
            hash,
            data: d,
            palpites: p,
          },
          config
        )
        .then(() => {
          setIsNew(false);
          setIsLoading(false);
          toast.success('Aposta atualizada com sucesso!!!');
          reload();
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
    } else {
      apiToken
        .post(
          `apostas`,
          {
            id: undefined,
            rodada: rodada?.id,
            apostador: parseInt(apostador),
            hashInicial: aposta?.hashInicial ? aposta.hashInicial : hash,
            hash,
            data: d,
            palpites: p,
          },
          config
        )
        .then(() => {
          setIsNew(false);
          reload();
          toast.success('Aposta adicionada com sucesso!!!');
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
    }
  };

  const editAposta = (aposta: ApostaDTO) => {
    setIsLoading(true);

    apiToken
      .get(`apostas/${aposta.id}`, config)
      .then((apostaRetorno: any) => {
        const palpites: number[] = [];
        const b = apostaRetorno.data.palpites.split('');
        b.map((p: any) => palpites.push(parseInt(p)));
        setPalpites(palpites);
        setAposta(aposta);
        setIsNew(true);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));

        setIsLoading(false);
      });
  };

  const reload = () => {
    setIsReloading(!isReloading);
  };

  return (
    <div>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-container-top">
            <div className="home-left-container-top-left">
              <div className="home-left-container-top-left-top">
                <div className="home-left-container-top-left-top-title">
                  <div className="home-left-container-top-left-top-title-text">
                    <h3>MEUS JOGOS</h3>
                    {rodada && (
                      <h6>
                        {rodada?.id}ª RODADA{' '}
                        {rodada?.status ? STATUS[rodada.status] : 'ENCERRADA'}
                      </h6>
                    )}
                    {rodada && (
                      <p>
                        Encerra em{' '}
                        {format(
                          rodada ? new Date(rodada.dtaFechamento) : new Date(),
                          'dd/MM/yyyy HH:mm'
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div className="home-left-container-top-right">
                  {!isNew && rodada && rodada.status === 1 && (
                    <ButtonIcon
                      text="NOVO PALPITE"
                      action={() => novaAposta()}
                      position="right"
                    />
                  )}
                  {cartelaGratis && <div
                  style={{fontSize: 14, fontWeight:'bold', color:'red', padding: '5px 0px 5px 0px', animation: 'blinker 1s linear infinite'}}
                  >ATENÇÃO !!!<br/>O PRÓXIMO É BONUS</div>}
                  {isNew && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ButtonIcon
                        text="CANCELAR"
                        action={() => cancelarEdicao()}
                        color="bg-danger btn-secondary"
                      />
                      <div style={{ width: '10px' }} />
                      <ButtonIcon text="SALVAR" action={() => salvarAposta()} />
                    </div>
                  )}
                </div>
              </div>

              <div className="home-left-container-top-left-area">
                {!isNew && (
                  <div className="container">
                    <div style={{ marginBottom: 30 }}>
                      {apostas &&
                        apostas.map((c, x) => (
                          <Cartela
                            key={c.id}
                            maxPontos={resumo.maxPontos}
                            aposta={c}
                            jogos={jogos}
                            reload={reload}
                            editable={rodada?.status === 1}
                            atualizarAposta={() => editAposta(c)}
                          />
                        ))}
                    </div>
                    {exibeNavegacao && (
                      <Navegador
                        pageConfig={pageConfig}
                        setPage={setPage}
                        elemento="Cartelas"
                      />
                    )}

                    {!exibeNavegacao && (
                      <div
                        className="box-navigator"
                        style={{
                          fontSize: 16,
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'red',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <div>
                          Selecione o resultado esperado para cada jogo
                          <br />
                          TIME VENCEDOR ou EMPATE Você ainda não realizou nenhum
                          palpite nesta rodada.
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {isNew && (
                  <Palpites
                    rodada={rodada}
                    configura={configura}
                    palpites={palpites}
                    valorPalpite={valorPalpite}
                    jogos={jogos}
                    qtdApostas = {apostas?apostas.length: 0}
                  />
                )}
                <div
                  style={{
                    fontSize: '14px',
                    marginTop: 15,
                    textAlign: 'center',
                  }}
                >
                  {isNew && (
                    <div>
                      Selecione o resultado esperado para cada jogo clicando no
                      brasão do time ou no X.
                      <br />
                      TIME VENCEDOR ou EMPATE
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-right-container">
          {user && <SelectRodada />}
          <JogosRodada />
          <div className="view-desktop">
            <ResumoRodada resumo={resumo}/>
          </div>
        </div>
        <div className="view-mobile">
          <div className="home-right-container">
            <ResumoRodada resumo={resumo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import {
  faBolt,
  faCancel,
  faCheckCircle,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiToken } from 'api';
import BetsService from 'contexts/BetsService';
import { format, parseISO } from 'date-fns';
import LancamentoDTO from 'dtos/LancamentoDTO';
import { useContext, useState } from 'react';
import { formataMoeda, mensagemErro } from 'Utils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './style.css';

type Props = {
  data: LancamentoDTO;
  config: any;
  reload: any;
  carteira: boolean;
};

const Lancamento = ({ data, config, reload, carteira }: Props) => {
  const { setIsLoading, user, toast }: any = useContext(BetsService);
  const [show, setShow] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const confirmarPix = (lanc: LancamentoDTO) => {
    setIsLoading(true);

    apiToken
      .get(`webhook/pix/${data.txId}`, config)
      .then(() => {
        setIsLoading(false);
        toast.success('Pix recebido com sucesso !!!');
        setShow(false);
        reload();
      })
      .catch((e) => {
        if (e.response.data.erros) {
          e.response.data.erros.map((a: any) => {
            toast.error(a.message);
          });
        } else {
          toast.error(mensagemErro(e));
          setIsLoading(false);
          setShow(false);
          reload();
        }
      });
  };

  
  const cancelarPix = (lanc: LancamentoDTO) => {
    setIsLoading(true);

    apiToken
      .get(`webhook/pix/${data.txId}/cancel`, config)
      .then(() => {
        setIsLoading(false);
        setShowCancel(false);
        toast.success('Pix cancelado com sucesso !!!');
        reload();
      })
      .catch((e) => {
        if (e.response.data.erros) {
          e.response.data.erros.map((a: any) => {
            toast.error(a.message);
          });
        } else {
          toast.error(mensagemErro(e));
          setIsLoading(false);
          setShowCancel(false);
          reload();
        }
      });
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', fontSize: 16 }}>
            CONFIRMAÇÃO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 14 }}>
          Confirma recebimento do PIX <strong> {data?.txId}</strong> no valor de{' '}
          <strong>{formataMoeda(data.valor)}</strong> do(a){' '}
          <strong>{data.playerName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            style={{ color: 'white' }}
            onClick={() => confirmarPix(data)}
          >
            CONFIRMAR
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancel} onHide={() => setShowCancel(false)} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', fontSize: 16 }}>
            CONFIRMAÇÃO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 14 }}>
          Confirma CANCELAMENTO do PIX <strong> {data?.txId}</strong> no valor de{' '}
          <strong>{formataMoeda(data.valor)}</strong> do(a){' '}
          <strong>{data.playerName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            NÃO
          </Button>
          <Button
            variant="primary"
            style={{ color: 'white' }}
            onClick={() => cancelarPix(data)}
          >
            SIM
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="linha"
        style={{
          backgroundColor:
            data.status === 'ATIVA' ? '#FAEDCA' : 'rgb(246, 240, 240)',
        }}
      >
        <div style={{ width: '70px' }}>
          <div className="label">ID</div> {data.locId?.padStart(4, '0')}
        </div>
        <div style={{ width: '250px', textAlign: 'left', fontSize: 12 }}>
          <div className="label">IDENTIFICADOR DA TRANSAÇÃO</div>
          {data.txId}
        </div>
        <div style={{ width: '160px', textAlign: 'left', fontSize: 12 }}>
          <div className="label">DATA</div>
          {format(parseISO(data.criacao), 'dd/MM/yyyy HH:mm:ss')}
        </div>
        <div style={{ width: '200px', fontSize: 12}}>
          <div className="label">USUÁRIO </div>
          {data.player.toString().padStart(6, '0')} - {data.playerName}
        </div>
        <div style={{ width: '90px', textAlign: 'right', fontWeight: 'bold' }}>
          <div className="label">VALOR</div>
          {formataMoeda(data.valor)}
        </div>
        <div style={{ width: '80px', display:'flex' }}>
          {user &&
            user.administrator === true &&
            !carteira &&
            data.status === 'ATIVA' && (<>
              <FontAwesomeIcon
                style={{
                  margin: 5,
                  fontSize: 25,
                  color: '#FF1B1C',
                  cursor:'pointer'
                }}
                icon={faBolt}
                className="icon-edit"
                title="Confirmar Recebimento do PIX"
                onClick={() => setShow(true)}
              />
              <FontAwesomeIcon
              style={{
                margin: 5,
                fontSize: 25,
                color: '#F96E46',
                cursor:'pointer'
              }}
              icon={faCancel}
              title="Cancele o depósito."
              className="icon-edit"
              onClick={() => setShowCancel(true)}

            /></>
            )}

          {user && carteira && data.status === 'ATIVA' && (
              <FontAwesomeIcon
                style={{
                  margin: 5,
                  fontSize: 25,
                  paddingRight: '20px',
                  color: '#F96E46',
                }}
                icon={faClock}
                title="Aguarde confirmação de pagamento."
                className="icon-edit"
              />
          )}
          {data.status === 'RECEBIDO' && (
            <FontAwesomeIcon
              style={{
                margin: 5,
                fontSize: 25,
                paddingRight: '20px',
                color: '#306B34',
              }}
              icon={faCheckCircle}
              className="icon-edit"
              title="PIX recebido"
            />
          )}
          {data.status === 'CANCELADO' && (
            <FontAwesomeIcon
              style={{
                margin: 5,
                fontSize: 25,
                paddingRight: '20px',
                color: '#F25757',
              }}
              icon={faCancel}
              className="icon-edit"
              title="PIX cancelado"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Lancamento;

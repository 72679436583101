import ResumoRodadaDTO from 'types/ResumoRodadaDTO';
import { formataPerc } from '../../Utils/index';
type Props = {
  resumo: ResumoRodadaDTO;
};

const ResumoRodada = ({ resumo }: Props) => {
  const formataMoeda = (valor: number) => {
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  return (
    <div>
      <div className="home-right-container-dados">
        <div className="home-right-container-top">RESUMO DA RODADA</div>
        <div className="dados">
          <h1>CARTELAS</h1>
          <h2>{resumo.qtdCartelas}</h2>
        </div>
        <div className="dados">
          <h1>VALOR UNITÁRIO</h1>
          <h2> {formataMoeda(resumo.valorUnitario)}</h2>
        </div>
        <div className="dados">
          <h1>TOTAL ARRECADADO</h1>
          <h2>{formataMoeda(resumo.totalArrecadado)}</h2>
        </div>

        {resumo.valorGarantia > 0 && (
          <div className="dados">
          <h1>GARANTIA</h1>
          <h3>{formataMoeda(resumo.valorGarantia)}</h3>
          </div>
          )}
          {resumo.totalRodada > 0 && (
          <div className="dados">
            <h1>PREMIO MÍNIMO DA RODADA </h1>
            <h3> {formataMoeda(resumo.totalRodada)}</h3>
          </div>)}
        <div className="home-right-container-top">PRÊMIO DA RODADA</div>

        <div className="dados">
          <h1>PREMIO DA RODADA {resumo.percPremio}%</h1>
          <h3> {formataMoeda(resumo.valorPremiacao)}</h3>
        </div>
        <div className="home-right-container-top">PRÊMIO ACUMULADO</div>

        <div className="dados">
          <h1>ACUMULADO DA RODADA {resumo.percAcumulado}%</h1>
          <h3>{formataMoeda(resumo.acumuladoRodada)}</h3>
        </div>
        {resumo.acumulado > 0 && (
        <div className="dados">
          <h1>JÁ ACUMULADO</h1>          
          <h3>{formataMoeda(resumo.acumulado)}</h3>
        </div>        
        )}
        {resumo.valorGarantiaAcumulado > 0 && (
          <div className="dados">
            <h1>GARANTIA ACUMULADO</h1>
            <h3>{formataMoeda(resumo.valorGarantiaAcumulado)}</h3>
          </div>
        )}
        <div className="dados">
          <h1>TOTAL ACUMULADO</h1>          
          <h3>{formataMoeda(resumo.acumuladoRodada + resumo.acumulado + resumo.valorGarantiaAcumulado)}</h3>
        </div>        
        <div className="dados">
          <h1>PREMIO TOTAL </h1>
          <h2 style={{color:'red'}}>{formataMoeda(resumo.valorPremiacao + resumo.acumulado + resumo.acumuladoRodada + resumo.valorGarantiaAcumulado)}</h2>
        </div>
      </div>
    </div>
  );
};

export default ResumoRodada;

import {
  faBolt,
  faCancel,
  faCheckCircle,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiToken } from 'api';
import BetsService from 'contexts/BetsService';
import { format, parseISO } from 'date-fns';
import LancamentoDTO from 'dtos/LancamentoDTO';
import { useContext, useState } from 'react';
import { formataMoeda, mensagemErro } from 'Utils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './style.css';
import SaqueDTO from 'dtos/SaqueDTO';

type Props = {
  data: SaqueDTO;
  config: any;
  reload: any;
  carteira: boolean;
};

const Saque = ({ data, config, reload, carteira }: Props) => {
  const { setIsLoading, user, toast }: any = useContext(BetsService);
  const [show, setShow] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

 

  return (
    <>
      <div
        className="linhaSaque"
        style={{
          backgroundColor:
            data.status === 'PENDENTE' ? '#FAEDCA' : 'rgb(246, 240, 240)',
        }}
      >
        <div style={{ width: '120px', textAlign: 'left', fontSize: 12 }}>
          <div className="label">DATA</div>
          {format(parseISO(data.criacao), 'dd/MM/yyyy HH:mm:ss')}
          <div className="label">DATA REALIZAÇÃO</div>
          {data.realizacao? format(parseISO(data.realizacao), 'dd/MM/yyyy HH:mm:ss'):""}
        </div>        
        <div style={{ width: '250px', textAlign: 'left', fontSize: 12 }}>
          <div className="label">SOLICITANTE </div>
          {data.name}<br/>      
          <div className="label">CHAVE PIX</div>
          {data.chave}
        </div>
        <div style={{ width: '200px', textAlign: 'left', fontSize: 12 }}>
          <div className="label">BANCO</div>
          {data.detalhes}<br/>
          <div className="label">ID. TRANSAÇÃO</div>
          {data.txId}
        </div>
      
        <div style={{ width: '90px', textAlign: 'right', fontWeight: 'bold' }}>
          <div className="label">VALOR</div>
          {formataMoeda(data.valor)}
        </div>
        <div style={{ width: '90px', textAlign: 'right', fontWeight: 'bold' }}>
          <div className="label">SITUAÇÃO</div>
          {data.status}
        </div>
        <div style={{ width: '80px', display:'flex' }}>
          {user &&
            user.administrator === true &&
            !carteira &&
            data.status === 'PENDENTE' && (<>
              <FontAwesomeIcon
                style={{
                  margin: 5,
                  fontSize: 25,
                  color: '#FF1B1C',
                  cursor:'pointer'
                }}
                icon={faBolt}
                className="icon-edit"
                title="Confirmar Transferência do PIX"
                onClick={() => setShow(true)}
              />
              <FontAwesomeIcon
              style={{
                margin: 5,
                fontSize: 25,
                color: '#F96E46',
                cursor:'pointer'
              }}
              icon={faCancel}
              title="Cancele o depósito."
              className="icon-edit"
              onClick={() => setShowCancel(true)}

            /></>
            )}

          {user && carteira && data.status === 'PENDENTE' && (
              <FontAwesomeIcon
                style={{
                  margin: 5,
                  fontSize: 25,
                  paddingRight: '20px',
                  color: '#F96E46',
                }}
                icon={faClock}
                title="Aguarde realização da transferência."
                className="icon-edit"
              />
          )}
          {data.status === 'RECEBIDO' && (
            <FontAwesomeIcon
              style={{
                margin: 5,
                fontSize: 25,
                paddingRight: '20px',
                color: '#306B34',
              }}
              icon={faCheckCircle}
              className="icon-edit"
              title="PIX recebido"
            />
          )}
          {data.status === 'CANCELADO' && (
            <FontAwesomeIcon
              style={{
                margin: 5,
                fontSize: 25,
                paddingRight: '20px',
                color: '#F25757',
              }}
              icon={faCancel}
              className="icon-edit"
              title="PIX cancelado"
            />
          )}
        </div>
      </div>          
    </>
  );
};

export default Saque;

import { apiToken } from 'api';
import BetsService from 'contexts/BetsService';
import RodadaDTO from 'dtos/RodadaDTO';
import { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { AxiosRequestConfig } from 'axios';
import { mensagemErro } from 'Utils';

const SelectRodada = () => {
  const { user, setRodada, rodada }: any = useContext(BetsService);
  const [rodadas, setRodadas] = useState<RodadaDTO[]>();

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  useEffect(() => {
    const loadRodadas = async () => {
      apiToken
        .get(`rodadas`, config)
        .then((resout) => {
          const rodadas = resout.data.content.filter((rr: any) => rr.status <3);
          setRodadas(rodadas);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
        });
    };
    loadRodadas();
  }, [rodada]);

  const onChangeSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;

    apiToken
      .get(`rodadas/${value}`, config)
      .then((resout) => {
        let rodada: RodadaDTO = resout.data;
        setRodada(rodada);
      })
      .catch((e) => {
        toast.error(mensagemErro(e));
      });
  };

  return (
    <div className="home-right-container-dados">
      <div className="home-right-container-top">RODADA SELECIONADA</div>
      <div className="dados">
        <select
          className="form-select"
          style={{ fontSize: '14px' }}
          onChange={onChangeSelect}
          value={rodada?.id}
        >
          {rodadas?.map((rr) => (
            <option value={rr.id} key={rr.id}>
              R{rr.id.toString().padStart(3, '0')} -{' '}
              {format(new Date(rr.dtaAbertura), 'dd/MM/yyy')} a{' '}
              {format(new Date(rr.dtaFechamento), 'dd/MM/yyy')}{' '}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectRodada;

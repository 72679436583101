import './style.css';
import { ReactComponent as Seta } from 'assets/images/seta.svg';
type Props = {
  action?: any;
  text: string;
  submit?: boolean;
  position?: string;
  color?: string;
};
const ButtonIcon = ({ action, text, submit, position,color }: Props) => {
  const isRight = position==='right';
  return (
    <div className={ `btn-container`}>
        {submit &&<div className='box-button'>
                 <button className={`btn ${color?color:'btn-primary'}`}   type="submit"><h6>{text}</h6></button>
            </div>
}
            {!submit &&<div className='box-button'>
                 <button className={`btn ${color?color:'btn-primary'}`} onClick={action}><h6>{text}</h6></button>
            </div>}
    </div>
  );
};

export default ButtonIcon;

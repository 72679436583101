import { format } from 'date-fns-tz';
import JogoDTO from 'dtos/JogoDTO';
import './style.css';
import iconeEmpate from 'assets/images/empate.png';
type Props = {
  partida: JogoDTO;
  palpite: number;
};

const Jogo = ({ partida, palpite }: Props) => {
  const acertouJogo = palpite === partida.resultado;
  return (
    <div
      className="jogo-container"
      title={`${format(
        partida ? new Date(partida.dataEvento) : new Date(),
        'dd/MM/yyyy HH:mm'
      )} - ${partida.local}`}
    >
      <div className="jogo-container-header">
        {partida.timeCasa.sigla} x {partida.timeFora.sigla}
      </div>

      <div
        className={
          partida.status > 0
            ? 'jogo-container-foot'
            : 'jogo-container-foot-none'
        }
      >
        {partida.placar && partida.status > 0
          ? partida.status === 3
            ? '***'
            : partida.placar
          : '***'}
      </div>
      {palpite !== 0 && (
        <div className="itens-jogos-logo">
          <a href={partida.link} target="_blank" rel="noreferrer">
            <img
              title={
                palpite === 1 ? partida.timeCasa.name : partida.timeFora.name
              }
              src={
                palpite === 1 ? partida.timeCasa.logo : partida.timeFora.logo
              }
              alt=""
            />
          </a>
        </div>
      )}
      {palpite === 0 && (
        <div className="itens-jogos-logo">
          <a href={partida.link} target="_blank" rel="noreferrer">
            <img title={'EMPATE'} src={iconeEmpate} alt="" />
          </a>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          width: '70px',
          justifyContent: 'center',
          alignContent: 'space-around',
        }}
      >
        {palpite !== 5 && partida.status > 0 && (
          <div className={acertouJogo ? 'sinalizador' : 'sinalizador-red'} />
        )}
        {palpite === 1 && (
          <div style={{ marginTop: '-5px' }}>{partida.timeCasa.sigla}</div>
        )}
        {palpite === 2 && (
          <div style={{ marginTop: '-5px' }}>{partida.timeFora.sigla}</div>
        )}
        {palpite === 0 && <div style={{ marginTop: '-5px' }}> EMPATE</div>}
      </div>
    </div>
  );
};
export default Jogo;

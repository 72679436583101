import PageConfig from "types/PageConfig";
import { carregaSaldoUsuario } from "Utils";

type Props = {
    pageConfig?: PageConfig;
    setPage: any
    elemento: string;
}

const Navegador = ({pageConfig, elemento, setPage}: Props) => {
  return (
    <>
      <div className="box-navigator-rodada">
        {pageConfig && (
          <nav>
            <ul className="pagination pagination-md">
              {pageConfig?.pages.map((pag) => (
                <li
                  key={pag}
                  className={
                    pag == pageConfig.currentPage
                      ? 'page-item active'
                      : 'page-item '
                  }
                >
                  <a
                    onClick={() => setPage(pag)}
                    className="page-link"
                    href="#"
                  >
                    {pag + 1}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        )}

        <div className="total-registros">
          Página {pageConfig ? pageConfig.currentPage + 1 : 0} /{' '}
          {pageConfig?.totalPages}
        </div>

        <div className="total-registros">
          {pageConfig?.totalElements} {elemento}
        </div>
      </div>
    </>
  );
};

export default Navegador;

import React, { useState, useContext, useEffect } from 'react';
import './style.css';
import './style.mobile.css';
import { apiToken, apiBasic } from 'api';
import ButtonIcon from 'components/ButtonIcon';
import BetsService from 'contexts/BetsService';
import { useHistory } from 'react-router-dom';

import CadUser from 'pages/CadUsers';

import { AxiosRequestConfig } from 'axios';
import { mensagemErro } from 'Utils';
import GridPremios from 'components/GridPremio';
import JogosRodada from 'components/JogosRodada';

type FormData = {
  login: string;
  senha: string;
  codigo?: string;
  nsenha?: string;
};

type FormCadastro = {
  nome: string;
  login: string;
  senha: string;
  senhaRepeticao: string;
  promotor: string;
  palpiteiro: string;
};
const Acesso = () => {
  const { setIsLoading, toast, setRodada, rodada, jogos }: any =
    useContext(BetsService);

  const [update, setUpdate] = useState(false);

  const history = useHistory();

  const [formData, setFormData] = useState<FormData>({
    login: '',
    senha: '',
    nsenha: '',
    codigo: '',
  });

  const { setUser }: any = useContext(BetsService);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const campo = event.target.name;
    const valor = event.target.value;
    setFormData({ ...formData, [campo]: valor });
  };

  const renewPassword = () => {
    setUpdate(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    apiBasic
      .post(
        '/oauth/token',
        {
          grant_type: 'password',
          username: formData.login,
          password: formData.senha,
        },
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then(function (res) {
        const { access_token, id, login, administrator } = res.data;
        setUser({
          id: id.toString().padStart(8, 0),
          name: login,
          image: undefined,
          token: access_token,
          administrator: administrator ? administrator : false,
        });

        const config: AxiosRequestConfig = {
          headers: { Authorization: `Bearer ${access_token}` },
        };

        apiToken
          .get(`rodadas/ativa`, config)
          .then((r) => {
            setRodada(r.data);
          })
          .catch((e) => {
            toast.error(mensagemErro(e));
            setIsLoading(false);
          });

        history.push('/');
        setIsLoading(false);
      })
      .catch((e) => {
        if (e?.response?.data?.error_description == 'Bad credentials') {
          toast.error('Não foi possível efetuar o seu acesso.');
          toast.error('Verifique usuário e senha e tente novamente.');
        } else {
          toast.error(mensagemErro(e));
        }
        setIsLoading(false);
      });
  };

  const [isNew, setIsNew] = useState(false);

  const cadastrar = () => {
    setIsNew(true);
  };

  const gerarCodigo = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!formData.login) {
      toast.error('Email não informado.');
    }

    if (formData.codigo) {
      apiBasic
        .put(`/users/${formData.login}/code`, {
          username: formData.login,
          password: formData.senha,
          newPassword: formData.nsenha,
          codigo: formData.codigo,
        })
        .then(function (res) {
          toast.success('Senha atualizada com sucesso !!!');
          setIsLoading(false);
          setUpdate(false);
        })
        .catch((e) => {
          toast.error(mensagemErro(e));
          setIsLoading(false);
        });
    } else {
      apiBasic
        .post(`/users/${formData.login}/code-generate`)
        .then(function (res) {
          toast.success(
            'Em alguns instante você será enviado um CÓDIGO DE RECUPERAÇÃO para o email informado !!!'
          );
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error('Não foi possível gerar código.');
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      <div>
        <div className="acesso-container">
          <div
            className={
              !isNew && !update
                ? 'acesso-right-container'
                : 'acesso-right-container-new'
            }
          >
            <div className="home-right-container-login">
              <div className="home-right-container-top"></div>
              {!isNew && (
                <>
                  <div className="home-right-container-top">
                    {' '}
                    {!update ? 'ACESSO' : 'ATUALIZAR SENHA'}
                  </div>
                  <div className="dados-acesso">
                    <form
                      onSubmit={update ? gerarCodigo : handleSubmit}
                      style={{ fontSize: 16 }}
                    >
                      <div style={{ fontSize: 14 }}>USUARIO (e-mail)</div>
                      <input
                        style={{ width: '100%' }}
                        name="login"
                        value={formData.login}
                        onChange={handleChange}
                      />
                      <div
                        style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}
                      >
                        {!update ? 'SENHA' : 'NOVA SENHA'}
                      </div>
                      <input
                        style={{ width: '100%' }}
                        name="senha"
                        value={formData.senha}
                        type="password"
                        onChange={handleChange}
                      />
                      {update && (
                        <>
                          <div
                            style={{
                              fontSize: 14,
                              marginTop: 10,
                              marginBottom: 5,
                            }}
                          >
                            REPITA A NOVA SENHA
                          </div>
                          <input
                            style={{ width: '100%' }}
                            name="nsenha"
                            value={formData.nsenha}
                            type="password"
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              fontSize: 14,
                              marginTop: 10,
                              marginBottom: 5,
                            }}
                          >
                            CÓDIGO DE CONFIRMAÇÃO
                          </div>
                          <input
                            style={{ width: '100%' }}
                            name="codigo"
                            value={formData.codigo}
                            type="password"
                            onChange={handleChange}
                          />
                        </>
                      )}
                      {!update && (
                        <div
                          style={{
                            paddingTop: '10px',
                            fontSize: 11,
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'right',
                            cursor: 'pointer',
                          }}
                        >
                          <a onClick={() => renewPassword()}>
                            Esqueceu a senha ?
                          </a>
                        </div>
                      )}

                      {!update && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px 0px',
                          }}
                        >
                          <ButtonIcon
                            submit={true}
                            text="LOGAR"
                            position={'right'}
                          />
                          <ButtonIcon
                            text="Cadastrar-se"
                            position={'right'}
                            action={() => cadastrar()}
                          />
                        </div>
                      )}

                      {update && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px 0px',
                            marginTop: '25px',
                          }}
                        >
                          <ButtonIcon
                            text="CANCELAR"
                            action={() => setUpdate(false)}
                            color="bg-danger"
                          />

                          <ButtonIcon
                            submit={true}
                            text={
                              !formData.codigo
                                ? 'GERAR CÓDIGO'
                                : 'ALTERAR SENHA'
                            }
                          />
                        </div>
                      )}
                    </form>
                  </div>
                </>
              )}

              {isNew && <CadUser setIsNew={setIsNew} />}
            </div>

            <GridPremios />
            <div className="dados-acesso">
              <div
                className="jogos-rodada"
                style={{
                  position: 'absolute',
                  top: '100px',
                  left: '50px',
                  borderRadius: '25px',
                  backgroundColor: 'white',
                }}
              >
                <JogosRodada />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acesso;
